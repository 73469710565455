import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, Paper, TableContainer, Tooltip, styled, tableCellClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Table.css";
import {
    handleCheckBoxChange,
    handleInputChange,
    handleSelectAllClick,
} from "./TableFunction";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CancelOutlined, CheckCircleOutlined, Download, VisibilityOutlined, AddIcon, Add, Loop } from "@mui/icons-material";
import SpringModal from "../Modal/Modal";
import StyledButton from "../Button/Button";
import ConfirmDialog from "../Dialog/Dialog";
import { useNavigate } from "react-router-dom";
import LogsIcon from "../../../Assets/Images/logs.svg"
import axios from "axios";
import ModalDetails from "../ModalDetails/ModalDetails";
import { Table } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableBody } from '@mui/material';
import { TableCell } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#881630",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const TableComponent = (props) => {
    const location = useNavigate();
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [openEksDetailsModal, setOpenEksDetailsModal] = useState(false);
    const [eksData, setEksData] = useState([]);

    const handleEksDetailsModal = (data, idx) => {
        setOpenEksDetailsModal(!openEksDetailsModal);
        setEksData([data[idx]])

    };
    // useEffect(()=>{
    //     console.log(eksData,'eksdata')
    // },[eksData])

    const handleDeleteConfirmation = (props, idx) => {
        setDeleteIndex([props[idx]._id, props[idx].type]);
        setDeleteConfirmation(true);
    };

    const handleDeleteCancel = () => {
        setDeleteIndex(null);
        setDeleteConfirmation(false);
    };

    const handleDeleteConfirmed = () => {
        props.handleDelete(deleteIndex);
        setDeleteIndex(null);
        setDeleteConfirmation(false);
    };

    const handleSubmitRedirectEKS = (data, idx) => {
        const paramType = data[idx].type;
        const id = data[idx]._id

        location(`/launcher/${paramType.toLowerCase()}/edit/${id}`, {
            state: {
                data: data[idx],
            },
        });
        // props.toggleOpenState();
    };

    async function downloadLogFile(row) {
        const clusterLogToBeDownloaded = row.type;

        const response = await axios.get(`/api/v1/${clusterLogToBeDownloaded.toLowerCase()}/logs/${row._id}`, { responseType: 'blob' });
        // Create a temporary <a> element to trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(response.data);
        downloadLink.download = `logs_${row._id}.zip`; // Change filename as needed
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        // Trigger the download
        downloadLink.click();
        // Clean up
        document.body.removeChild(downloadLink);
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" >
                    <TableHead>
                        <TableRow>
                            {props.column &&
                                props.columns.map((val) => (
                                    <>
                                        <StyledTableCell align={["Action", "Application Status", "Cluster Status"].includes(val.name) ? "center" : "left"} >
                                            {val.name === "CheckAll" ? (
                                                <input
                                                    type={"checkbox"}
                                                    checked={props.checkboxData.length === props.rows.length}
                                                    onChange={(e) =>
                                                        handleSelectAllClick(e, props.rows, props.setCheckBoxData)
                                                    }
                                                    style={{ border: "1px solid black", borderRadius: "5px" }}
                                                />
                                            ) : (
                                                val.name
                                            )}
                                        </StyledTableCell>
                                    </>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody className="tableBody">
                        {props.rows.length === 0 && (
                            <StyledTableRow>
                                <StyledTableCell align="center">
                                    <div>{props.loading ? (
                                        <CircularProgress />
                                    ) : props.customTableMessage ? (
                                        props.customTableMessage
                                    ) : (
                                        "No Data Found"
                                    )}</div>
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                        {props.rows.length !== 0 && props.loading ? (
                            <StyledTableCell align="center">
                                {" "}
                                <CircularProgress />{" "}
                            </StyledTableCell>
                        ) : (
                            props.rows.map((row, idx) => (
                                <StyledTableRow>
                                    {props.columns.map((val) => (
                                        <>
                                            {val.type === "status" && (
                                                <StyledTableCell align="center" >
                                                    {
                                                        row[val.key] === 'Active' ? (<p>{row[val.key]}</p>) :
                                                            row[val.key] === 'Inactive' ? (<p style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                                                <CircularProgress color="primary" size={22} />
                                                                Inactive</p>) :
                                                                row[val.key] === 'Failed' ? (<p>Failed</p>) : null
                                                    }
                                                </StyledTableCell>
                                            )}
                                            {val.type === "clusterStatus" && (
                                                <StyledTableCell align="center" >
                                                    {
                                                        row[val.key] === 'Active' ? (<p>{row[val.key]}</p>) :
                                                            row[val.key] === 'Inactive' ? (<p>Inactive</p>) :
                                                                row[val.key] === 'Failed' ? (<p>Failed</p>) : null
                                                    }

                                                </StyledTableCell>
                                            )}
                                            {val.type === "os" && (
                                                <StyledTableCell align="center" >
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <p className={`table-status table-status-${row[val.key] === 'inactive' ? 'danger' : row[val.key] === 'completed' ? 'success' : 'warning'}`}>{row[val.key]}</p>
                                                    </div>
                                                </StyledTableCell>
                                            )}
                                            {
                                                val.type === "bool" && (
                                                    <StyledTableCell align="center" >
                                                        <div>
                                                            {
                                                                row[val.key] ?
                                                                    (<CheckCircleOutlined style={{ color: "green" }} />)
                                                                    :
                                                                    (<CancelOutlined style={{ color: "red" }} />)

                                                            }
                                                        </div>
                                                    </StyledTableCell>
                                                )
                                            }
                                            {val.type === "label" &&
                                                <StyledTableCell >
                                                    {
                                                        val.key == "_id" ? <Link onClick={() => props.viewMoreDetails(row)} >
                                                            {row[val.key]} {val.suffix}
                                                        </Link> : <div >{row[val.key]} {val.suffix}</div>
                                                    }

                                                </StyledTableCell>
                                            }
                                            {val.type === "action" && (
                                                <StyledTableCell align="center" >
                                                    <div >
                                                        {(row.status === "Active" || row.status === "Failed") && props.handleDelete && (
                                                            <Tooltip title="Delete Cluster">
                                                                <IconButton size="large" onClick={() => handleDeleteConfirmation(props.rows, idx)}>
                                                                    <DeleteIcon style={{ color: "black", }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        {/* {(row.status === "Failed" && row.scriptError === false) && props.handleDelete && (
                                                        <EditIcon onClick={() => handleSubmitRedirectEKS(props.rows, idx)} className="table-action-edit-btn" />
                                                    )} */}
                                                        {/* {(row.status === "Failed") && props.handleReapply && (
                                                        <Loop onClick={() => props.handleReapply(row, idx)} className="table-action-show-btn" />
                                                    )} */}

                                                        {props.showDetails && (
                                                            <IconButton size="large" onClick={() => props.showDetails(idx)}>
                                                                <VisibilityOutlined style={{ color: "black" }} />
                                                            </IconButton>
                                                        )}
                                                        {/* {props.viewMoreDetails && (
                                                            <VisibilityOutlined onClick={() => props.viewMoreDetails(row)} className="table-action-show-btn" />
                                                        )} */}
                                                        {row.status === "Active" && props.handleDelete && (
                                                            <Tooltip title="Add Edge">
                                                                <IconButton size="large" onClick={() => handleEksDetailsModal(props.rows, idx)}>
                                                                    <Add style={{ color: "black" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        {row.status === "Failed" && (
                                                            <>
                                                                <Tooltip title="Download Log">
                                                                    <IconButton aria-label="edit" size="small" onClick={() => downloadLogFile(row)}>
                                                                        <img src={LogsIcon} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                        {!(
                                                            row.status === "Active" && props.handleDelete ||
                                                            row.status === "Active" && props ||
                                                            props.showDetails ||
                                                            props.viewMoreDetails ||
                                                            row.status === "Failed"
                                                        ) && <span>N/A</span>}
                                                    </div>
                                                </StyledTableCell>
                                            )}

                                            {val.type === "download" && (
                                                <StyledTableCell align="center" >
                                                    <Tooltip title="Download PemKey">
                                                        {row[val.key] === "Not Assigned" || row.status !== "Active" ? (
                                                            <div>
                                                                {props.handleDownload &&
                                                                    <IconButton disabled>
                                                                        <Download />
                                                                    </IconButton>
                                                                }
                                                            </div>) : (
                                                            <div >
                                                                {props.handleDownload &&
                                                                    <IconButton onClick={() => props.handleDownload(props.rows, idx)}>
                                                                        <Download style={{ color: "black" }} />
                                                                    </IconButton>}
                                                            </div>)}
                                                    </Tooltip>
                                                </StyledTableCell>
                                            )}

                                            {val.type === "microCloudInfo" && (
                                                <StyledTableCell>

                                                    <div className="table-action-btn">

                                                        {row[val.key] === true ? (<VisibilityOutlined onClick={() => props.setOpen(true)}
                                                            className="table-action-download-btn" />) : (<VisibilityOutlined onClick={() => { props.setOpen(true) }}
                                                                className="table-action-download-btn" />)}
                                                    </div>
                                                </StyledTableCell>
                                            )}
                                            {val.editable === true && (
                                                <StyledTableCell>
                                                    {val.type !== "checkbox" && (
                                                        <input
                                                            type={val.type}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    props.setTableServerData,
                                                                    props.rows,
                                                                    e,
                                                                    idx,
                                                                    val.key
                                                                )
                                                            }
                                                            style={{
                                                                border: "1px solid rgba(0, 0, 0, 0.336)",
                                                                borderRadius: "5px",
                                                                outline: "none"
                                                            }}
                                                            placeholder={val.placeholder}
                                                            value={row[val.key]}
                                                        />
                                                    )}

                                                    {val.type === "checkbox" && (
                                                        <input
                                                            type={"checkbox"}
                                                            checked={
                                                                props.checkboxData.indexOf(row[val.key]) !== -1
                                                            }
                                                            onChange={() =>
                                                                handleCheckBoxChange(
                                                                    props.setCheckBoxData,
                                                                    props.checkboxData,
                                                                    row[val.key],
                                                                    props.maxCheck
                                                                )
                                                            }
                                                            style={{
                                                                border: "1px solid black",
                                                                borderRadius: "5px",
                                                            }}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                            )}
                                        </>
                                    ))}
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                    <ConfirmDialog open={deleteConfirmation} handleClose={handleDeleteCancel} handleSubmit={handleDeleteConfirmed}
                        title={"Alert!!! Delete"}
                        content={"Please beware that all the data will be lost and the cluster will be deleted"} />
                </Table>
            </TableContainer >
            <ModalDetails open={openEksDetailsModal} handleClose={() => setOpenEksDetailsModal(false)} eksData={eksData} />
        </>
    );
};

export default TableComponent;
