import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CachedOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DialogActions from "@mui/material/DialogActions";
import axios from "axios";

import StyledButton from "../../Components/Element/Button/Button";
import BlockBox from "../../Components/Element/Box/Box";
import Searchbar from "../../Components/Element/Searchbar/Searchbar";
import "./AiMicrocloud.css";
import Table from "../../Components/Element/Table/Table";
import SpringModal from "../../Components/Element/Modal/Modal";
import ChooseCluster from "./ChooseCluster/ChooseCluster";
import SnackbarComponent from "../../Components/Element/Snackbar/Snackbar";

const kubClusterHeaders = [
    { name: "Kubernetes ID", type: "label", key: "k8sId" },
    { name: "Kubernetes Name", type: "label", key: "k8sClusterName" },
    { name: "Server Status", type: "status", key: "status" },
    { name: "MicroCloud", type: "status", key: "isAiMicroCloudInstalled" },
    { name: "Action", type: "microCloudInfo", key: "isAiMicroCloudInstalled" },
];

const AiMicrocloud = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openMicroCloudModel, setOpenMicroCloudModel] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarData, setSnackbarData] = useState({
        message: "",
        severity: "success",
    });
    const [open, setOpen] = useState(false); // State for the modal

    const getClustersData = async () => {
        setIsLoading(true);
        Promise.all([
            axios.get("/api/v1/eks"),
            axios.get("/api/v1/aks")
        ]).then(([eksRes, aksRes]) => {
            const eksData = eksRes.data.data.map((item) => {
                return {
                    k8sId: item._id.slice(-8),
                    k8sClusterName: item.orgName,
                    status: item.status,
                    isAiMicroCloudInstalled: item.isAiMicroCloudInstalled,
                };
            });
            const aksData = aksRes.data.data.map((item) => {
                return {
                    k8sId: item._id.slice(-8),
                    k8sClusterName: item.ResourceGroupName,
                    status: item.status,
                    isAiMicroCloudInstalled: item.isAiMicroCloudInstalled,
                };
            });
            setTableData([...eksData, ...aksData]);
            setIsLoading(false);
        }).catch(error => {
            console.error("Error fetching cluster data:", error);
            setIsLoading(false);
        });
    };

    const handleAddMicroCloudToggle = () => {
        setOpenMicroCloudModel(!openMicroCloudModel);
        getClustersData();
    };

    const handleMicroCloudRefresh = () => {
        setIsLoading(true);
        Promise.all([
            axios.get("/api/v1/eks"),
            axios.get("/api/v1/aks")
        ]).then(([eksRes, aksRes]) => {
            const eksClusters = eksRes.data.data.map((item) => {
                return {
                    id: item._id,
                    name: item.orgName,
                };
            });
            const aksClusters = aksRes.data.data.map((item) => {
                return {
                    id: item._id,
                    name: item.orgName,
                };
            });
            const allClusters = [...eksClusters, ...aksClusters];
    
            axios.post("/api/v1/eks/refreshMicrocloud", { clusters: eksClusters })
                .then((eksRefreshRes) => {
                    const eksData = eksRefreshRes.data.data.map((item) => {
                        return {
                            k8sId: item._id.slice(-8),
                            k8sClusterName: item.orgName,
                            status: item.status,
                            isAiMicroCloudInstalled: item.isAiMicroCloudInstalled,
                        };
                    });
                    setTableData(eksData);
                })
                .catch((error) => {
                    console.error("Error refreshing EKS microcloud:", error);
                });
    
            axios.post("/api/v1/aks/refreshMicrocloud", { clusters: aksClusters })
                .then((aksRefreshRes) => {
                    const aksData = aksRefreshRes.data.data.map((item) => {
                        return {
                            k8sId: item._id.slice(-8),
                            k8sClusterName: item.orgName,
                            status: item.status,
                            isAiMicroCloudInstalled: item.isAiMicroCloudInstalled,
                        };
                    });
                    setTableData(prevData => [...prevData, ...aksData]);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error("Error refreshing AKS microcloud:", error);
                    setIsLoading(false);
                });
        }).catch(error => {
            console.error("Error fetching cluster data:", error);
            setIsLoading(false);
        });
    };
    

    useEffect(() => {
        getClustersData();
    }, []);

    return (
        <>
            <SnackbarComponent
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarData.message}
                severity={snackbarData.severity}
            />
            <SpringModal open={openMicroCloudModel}>
                <ChooseCluster
                    setOpen={setOpenMicroCloudModel}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarData={setSnackbarData}
                />
            </SpringModal>
            <div className="server-main">
                <div className="server-box">
                    <BlockBox>
                        <div className="server-block-header">
                            <div className="server-box-title">Configure Ai-Microcloud</div>
                            <div className="server-block-header-right">
                                <div style={{ marginRight: "15px" }}>
                                    <Searchbar />
                                </div>
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px",
                                    }}
                                    name={"Add Ai-Microcloud"}
                                    endIcon={<AddIcon />}
                                    onClickFunc={handleAddMicroCloudToggle}
                                />
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px",
                                    }}
                                    name={"Refresh Status"}
                                    endIcon={<CachedOutlined />}
                                    onClickFunc={handleMicroCloudRefresh}
                                />
                            </div>
                        </div>

                        <div className="server-table-cover">
                            <Table
                                columns={kubClusterHeaders}
                                loading={isLoading}
                                customTableMessage={"No Clusters Found"}
                                column={true}
                                rows={tableData}
                                setOpen={setOpen}
                            />
                        </div>
                    </BlockBox>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{
                    style: {
                        maxWidth: "500px",
                        padding: "0",
                        borderRadius: "10px",
                        boxShadow: "0 0 15px 0 #e1e5ee",
                        background: "white",
                        overflow: "scroll",
                    },
                }}
            >
                <DialogTitle
                    style={{
                        background: "#ffc000",
                        color: "white",
                        padding: "1rem 0rem",
                        textAlign: "center",
                        fontSize: "0.7rem",
                        fontWeight: "600",
                        textTransform: "uppercase",
                        letterSpacing: "0.1rem",
                    }}
                >
                    Info
                </DialogTitle>
                <DialogContent>
                    <p
                        style={{
                            padding: "1rem 2rem",
                            fontSize: "0.8rem",
                        }}
                    >
                        Please map your Elastic IP with your domain. Then open{" "}
                        https://app.domain.zeblok.com

                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AiMicrocloud;
