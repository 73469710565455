import React, { useEffect, useState } from "react";
import axios from "axios";

import StyledButton from "../../../Components/Element/Button/Button";
import Table from "../../../Components/Element/Table/Table";
import "./CsvTable.css";

const tableHeaders = [
    { name: "Management MAC Address", type: "text", editable: true, key: "mgMacAdd", placeholder: "Enter Management MAC Address" },
    { name: "BMC IP", type: "ip", editable: true, key: "bmcip", placeholder: "Enter BMC IP" },
    { name: "Management IP", type: "ip", key: "managementip", editable: true, placeholder: "Enter Management IP" },
    { name: "Data IP", type: "ip", key: "dataip", editable: true, placeholder: "Enter Data IP" },
    { name: "Username", type: "text", editable: true, key: "username", placeholder: "Enter Username" },
    { name: "Password", type: "password", editable: true, key: "password", placeholder: "Enter Password" },
    { name: "Hostname", type: "text", editable: true, key: "hostname", placeholder: "Enter Hostname" },
    { name: "Data Gateway", type: "text", editable: true, key: "dataGateway", placeholder: "Enter Gateway" },
    { name: "Data Interface", type: "text", editable: true, key: "dataInterface", placeholder: "Enter Data Interface" },
    { name: "Management Interface", type: "text", editable: true, key: "mgInterface", placeholder: "Enter Management Interface" },
    { name: "Action", type: "action", key: "action" },
];

const Csvtable = (props) => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const addNewRow = () => {
        const emptyRow = {
            mgMacAdd: "",
            bmcip: "",
            managementip: "",
            dataip: "",
            username: "",
            password: "",
            hostname: "",
            dataGateway: "",
            dataInterface: "",
            mgInterface: "",
        };
        setTableData([...tableData, emptyRow]);
    }

    const importCSV = () => {
        document.getElementById("docpicker").click();
    }

    function handleCsvImport() {
        const file = document.getElementById("docpicker").files[0];
        const reader = new FileReader();
        if (file) reader.readAsText(file);
        reader.onload = async function () {
            const csv = reader.result;
            const lines = csv.split("\n");
            console.log(lines);
            const result = [];
            const headerRow = lines[0].split(",");
            for (let i = 1; i < lines.length; i++) {
                const row = lines[i].split(",");
                const obj = {};
                for (let j = 0; j < headerRow.length; j++) {
                    obj[headerRow[j]] = row[j];
                }
                if (obj.managementip && obj.dataip && obj.bmcip && obj.username && obj.password) {
                    result.push(obj);
                }
            }
            setTableData(result);
        };
    }

    const checkData = () => {
        tableData.forEach((item) => {
            if (item.managementip === "" || item.dataip === "" || item.bmcip === "" || item.username === "" || item.password === "" || item.interface === "" || item.hostname === "") {
                setButtonDisabled(true)
            } else {
                setButtonDisabled(false)
            }
        })
    };

    const handleDelete = (idx) => {
        console.log(idx)
        const removedData = [...tableData];
        removedData.splice(idx, 1);
        setTableData(removedData);
    }

    const sendData = () => {
        if (tableData.length === 0) return;
        tableData.forEach((item) => {
            console.log(item);
            console.log("Please fill all the fields");
            return;
        })
        setIsLoading(true)
        axios.post("/api/v1/server", { servers: tableData, osInstall: props?.OsInstalled }).then((res) => {
            console.log(res);
            props.setSnackbarOpen(true)
            props.setSnackbarData({
                message: "Servers added Successfully",
                severity: "success"
            })
        }).catch((err) => {
            props.setSnackbarOpen(true)
            props.setSnackbarData({
                message: err.message,
                severity: "error"
            })
            console.log(err)
            return;
        });
        setIsLoading(false)
        props.toggleOpenState();
    };
    useEffect(() => {
        checkData();
    }, [tableData]);
    return (
        <div className="csv-main">
            <div className="csv-headers">
                <div className="csv-title">Create Server</div>
                <div className="csv-closeX" onClick={props.toggleOpenState}>X</div>
            </div>
            <div className="csv-box csv-end">
                <div className="csv-button-top">
                    <input type="file" onChange={(e) => handleCsvImport(e)} style={{ display: "none" }} id="docpicker"
                        accept="text/csv" multiple />

                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "#881630",
                            bghover: "#ffc000",
                        }}
                        name={"Import Csv"}
                        onClickFunc={importCSV}
                    />
                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "#881630",
                            bghover: "#ffc000",
                        }}
                        onClickFunc={addNewRow}
                        name={"Add New"}
                    />
                </div>
            </div>
            <div className="csv-box csv-table">
                <div className="csv-table-cover">
                    <Table
                        loading={isLoading}
                        tableStyle={{ fontsize: "12px" }}
                        columns={tableHeaders}
                        rows={tableData}
                        maxHeight={"10em"}
                        setTableServerData={setTableData}
                        handleDelete={handleDelete}
                    />
                </div>
            </div>
            <div className="csv-box csv-mid">
                <div className="csv-button-top">
                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "white",
                            bghover: "#881630",
                            colorhover: "white",
                            color: "#881630",
                            border: "2px solid #881630"
                        }}
                        name={"Cancel"}
                        onClickFunc={props.toggleOpenState}
                    />
                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "#881630",
                            bghover: "#ffc000",
                        }}
                        disabled={buttonDisabled}
                        onClickFunc={sendData}
                        name={"Validate"}
                    />
                </div>
            </div>
        </div>
    );
};

export default Csvtable;
