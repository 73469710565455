import React from "react";
import {
    Grid,
    TextField,
} from "@mui/material";

function DNSSSL({
    formFelids, handleFormChange
}) {
    return (
        <Grid container spacing={4}>
            <Grid item>
                <TextField
                    id="outlined-basic"
                    label="Company Name"
                    variant="outlined"
                    required
                    name="companyName"
                    value={formFelids.companyName}
                    onChange={handleFormChange}
                    placeholder="abc"
                />
            </Grid>
            <Grid item>
                <TextField
                    id="outlined-basic"
                    label="DNS host Name"
                    variant="outlined"
                    required
                    name="DNSHostname"
                    value={formFelids.DNSHostname}
                    onChange={handleFormChange}
                    placeholder="e.g: abc@xyz.com"
                />
            </Grid>
            <Grid item>
                <TextField
                    id="outlined-basic"
                    label="Bundeld CERT"
                    required
                    variant="outlined"
                    name="BundledCERT"
                    value={formFelids.BundledCERT}
                    onChange={handleFormChange}
                    multiline
                    rows={4}
                    placeholder="CA BUNDLE: A CA bundle can be created from multiple CRT (Certificate files) by concatenating them into a single files. On how to create a bundle crt file please visit our documentation page."
                />
            </Grid>
            <Grid item>
                <TextField
                    id="outlined-basic"
                    label="CERT Key"
                    required
                    variant="outlined"
                    name="CERTKey"
                    value={formFelids.CERTKey}
                    onChange={handleFormChange}
                    multiline
                    rows={4}
                />
            </Grid>
        </Grid>
    )
}

export default DNSSSL