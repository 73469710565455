import React from "react";
import { useLocation } from "react-router-dom";

import "./EditAksCluster.css"

import AKSForm from "./AksEditClusterForm/AksEditClusterForm";

const EditAksCluster = () => {
  const location = useLocation();
  
  return (
    <div> <AKSForm  propsData={location.state.data}/></div>
  );
};

export default EditAksCluster;
