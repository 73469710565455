import { Close, Height } from "@mui/icons-material";
import SpringModal from "./Modal";
import "./Table.css";
import { Accordion, AccordionDetails, AccordionSummary, Box, Modal, Typography } from "@mui/material";

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: "50%"
};
const ViewMoreModal = (props) => {
    console.log(props.rows)
    return (
        <Modal sx={{ maxHeight: "60vh" }} open={props.isOpen} onClose={props.handleClose}
        >
            <Box sx={style}>
                {/* <div className="custom-modal-header">
                <div className="custom-close-btn" onClick={props.handleClose}>
                    <Close />
                </div>
            </div> */}
                {
                    props.columns.map((item, index) => {
                        return <Accordion defaultExpanded={index == 1}>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>{props.sections[index]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item?.map((header) => (
                                    <div className="details-acc">
                                        <h4 key={header.key}>{header.name}</h4>:
                                        <h4>{props?.rows?.[0]?.[header?.key]} {header.suffix}</h4>
                                    </div>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    })
                }

            </Box>
        </Modal >
    )
}



export default ViewMoreModal;