import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import zeblokLogo from "../../Assets/Images/logo.svg";
import toggleIcon from "../../Assets/Images/toggle.svg";
import zeblokIcon from "../../Assets/Images/zebloklogo.png";
import { AddAlarm, AddBox, CloudCircle, Language, Logout, RequestPage, Security } from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";
import { LayoutBarOptions } from "./LayoutConstants";
import { useLocation } from "react-router-dom";
import { AppBar, Drawer, DrawerHeader, StyledToolbar } from "./LayoutUtils";
import AzureIcon from "../../Assets/Images/azure.png"
import AWSIcon from "../../Assets/Images/AWS.png"
import SupportButton from "./Support"
const drawerWidth = 240;

export default function MainLayout() {
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const navigation = useNavigate();
    const handleDrawer = () => {
        setOpen(!open);
    };

    const toggleSupport = () => {
        // Add your logic here to toggle the support popup or perform other actions
        console.log('hi');
    };
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <StyledToolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawer}
                        edge="start"
                        sx={{
                            marginRight: 1,
                        }}
                    >
                        <img src={toggleIcon} alt={"Toggle button"} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        sx={{ color: "black" }}
                        noWrap
                        component="div"
                    >
                        {location.pathname === "/launcher/request" && "Request"}
                        {location.pathname === "/launcher/eks" && "Dashboard"}
                        {/* {location.pathname === "/launcher/addedge" && "Add Edge"} */}
                        {location.pathname === "/launcher/security" && "Security"}
                        {/* {location.pathname === "/launcher/aimicrocloud" && "Ai-Microcloud"} */}
                        {location.pathname === "/logout" && "Logout"}

                    </Typography>
                </StyledToolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    {open ? (
                        <img src={zeblokLogo} alt={"Zeblok Logo"} />
                    ) : (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawer}
                        >
                            <img src={zeblokIcon} alt={"Toggle button"} width={"40px"} />
                        </IconButton>
                    )}
                </DrawerHeader>
                <List>
                    {LayoutBarOptions.map((text) => (
                        <ListItem
                            key={text.link}
                            disablePadding
                            onClick={() => navigation(text.link)}
                            sx={{
                                display: "block",
                                background: `${`${location.pathname}*${text.name}` === "/launcher/request*Request" ||
                                    `${location.pathname}*${text.name}` === "/launcher/eks*Dashboard" ||
                                    `${location.pathname}*${text.name}` === "/launcher/aimicrocloud*Ai-Microcloud" ||
                                    `${location.pathname}*${text.name}` === "/launcher/addedge*Add Edge" ||
                                    `${location.pathname}*${text.name}` === "/launcher/security*Security"
                                    ? "#e5e5e5" : "none"
                                    }`,
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    {text.name === "Request" && <RequestPage />}
                                    {text.name === "AWS" && <img src={AWSIcon} width={40} height={40} />}
                                    {text.name === "AZURE" && <img src={AzureIcon} width={40} height={40} />}
                                    {text.name === "Add Edge" && <AddBox />}
                                    {text.name === "Security" && <Security />}
                                    {text.name === "Ai-Microcloud" && <CloudCircle />}
                                    {text.name === "Logout" && <Logout />}
                                </ListItemIcon>
                                <ListItemText
                                    primary={text.name}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: open ? `calc(100vw - ${drawerWidth}px)` : "95vw",
                    overflow: "scroll",
                    WebkitOverflowScrolling: "none"
                }}
            >
                <DrawerHeader />
                <Outlet />
                <  SupportButton />
            </Box>
        </Box>
    );
}
