import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { CachedOutlined, Add } from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Searchbar from "../../Components/Element/Searchbar/Searchbar";
import BlockBox from "../../Components/Element/Box/Box";
import StyledButton from "../../Components/Element/Button/Button";
import "./EKS.css";
import Table from "../../Components/Element/Table/Table";
import SnackbarComponent from "../../Components/Element/Snackbar/Snackbar";
import { getEksClusterData, getAksClusterData, refreshEksClusterData } from "../../slice/clusterSlice";
import ViewMoreModal from "../../Components/Element/Modal/ViewMore";


const tableHeadersEks = [
  { name: "Cluster ID", type: "label", key: "_id" },
  { name: "Org Name", type: "label", key: "orgName" },
  // { name: "Region", type: "label", key: "region" },
  // { name: "Instance Type", type: "label", key: "instanceType" },
  // {
  //   name: "Primary Storage",
  //   suffix: "GB",
  //   type: "label",
  //   key: "primaryStorage",
  // },
  // {
  //   name: "Secondary Storage",
  //   suffix: "GB",
  //   type: "label",
  //   key: "secondaryStorage",
  // },
  { name: "Elastic IP", type: "label", key: "elastic_ip" },
  { name: "Cluster Status", type: "status", key: "status" },
  { name: "Application Status", type: "status", key: "appStatus" },
  { name: "Action", type: "action", key: "pem_key" },
  { name: "Download", type: "download" },
];
const tableHeadersViewMore = [
  [
    { name: "Cluster ID", type: "label", key: "_id" },
    { name: "Org Name", type: "label", key: "orgName" },
    { name: "Instance Type", type: "label", key: "instanceType" },
    { name: "Region", type: "label", key: "region" },
  ],
  [
    {
      name: "Primary Storage",
      suffix: "GB",
      type: "label",
      key: "primaryStorage",
    },
    {
      name: "Secondary Storage",
      suffix: "GB",
      type: "label",
      key: "secondaryStorage",
    }
  ],
  [
    {
      name: "Company Name",
      type: "companyName",
      key: "companyName",
    },
    {
      name: "cert Key",
      type: "certKey",
      key: "certKey",
    },
    {
      name: "bundled Certificate",
      type: "bundledCert",
      key: "bundledCert",
    },
    {
      name: "DNS Hostname",
      type: "DNSHostname",
      key: "DNSHostname",
    },
  ]
];

const EKS = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    severity: "success",
  });
  const [viewMoreModal, setViewMoreModal] = useState(false);
  const [viewMoreData, setViewMoreData] = useState([]);
  const [value, setValue] = useState(Number(location?.state?.value) || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isLoading = useSelector((state) => state.cluster.loading);

  const EksData = useSelector((state) => state.cluster.eks_cluster_data);

  const handleDownload = async (val, id) => {
    const doc_id = val[id]._id;
    const clusterToBeDownloaded = val[id].type;

    axios
      .get(`/api/v1/${clusterToBeDownloaded.toLowerCase()}/${doc_id}/download`)
      .then(async (res) => {
        // save response as file
        const blob = await res.data;
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        const fileName =
          res.headers["content-disposition"].substring(22, 52);
        fileLink.setAttribute("download", fileName);
        fileLink.setAttribute("target", "_blank");
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();

        setSnackbarData({
          message: "File Downloaded Successfully",
          severity: "success",
        });
        setSnackbarOpen(true);
      })
      .catch((err) => {
        setSnackbarData({ message: "File Download Failed", severity: "error" });
        setSnackbarOpen(true);
      });
  };

  const handleDelete = async (data) => {
    try {
      let doc_id;
      let dataArr = EksData;

      const doc = dataArr.find(item => item._id === data[0]);

      if (doc) {
        doc_id = doc._id;

        const response = await axios.delete(`/api/v1/eks/${doc_id}`);

        if (response.status === 200) {
          setSnackbarData({
            message: "Item deleted successfully!",
            severity: "success",
          });
          setSnackbarOpen(true);
          // dispatch(getAksClusterData());
          dispatch(getEksClusterData());
        } else {
          setSnackbarData({ message: "Failed to delete item.", severity: "error" });
          setSnackbarOpen(true);
        }
      } else {
        setSnackbarData({ message: "Data not found", severity: "error" });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarData({ message: "Failed to delete item.", severity: "error" });
      setSnackbarOpen(true);
    }
  };

  function viewMoreDetails(data) {
    setViewMoreData([data]);
    setViewMoreModal(!viewMoreModal);
  }

  async function handleReapply(data) {

    const response = await axios.post(`/api/v1/eks/reapply/${data._id}`);

    if (response.status === 200) {
      setSnackbarData({
        message: "Re-applied successfully!",
        severity: "success",
      });
      setSnackbarOpen(true);
    } else {
      setSnackbarData({ message: "Failed to re-apply.", severity: "error" });
      setSnackbarOpen(true);
    }

  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    // dispatch(getAksClusterData())
    dispatch(getEksClusterData());
  }, []);

  return (
    <div>
      <SnackbarComponent
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <div className="server-main">
        <div className="server-box">
          <BlockBox>
            <div className="server-block-header">
              <div className="server-box-title">All AWS Clusters</div>
              <div className="server-block-header-right">
                <div style={{ marginRight: "15px" }}>
                  <Searchbar />
                </div>
                <StyledButton
                  styleDetails={{
                    padding: "9px 15px",
                    borderRadius: "10px",
                    bgcolor: "#881630",
                    bghover: "#ffc000",
                    margin: "0 5px",
                  }}
                  name={"Refresh"}
                  endIcon={<CachedOutlined />}
                  onClickFunc={() => dispatch(refreshEksClusterData())}
                />
              </div>
            </div>
            <div className="server-table-cover">
              <Table
                columns={tableHeadersEks}
                handleDownload={handleDownload}
                loading={isLoading}
                column={true}
                rows={EksData}
                handleDelete={handleDelete}
                handleReapply={handleReapply}
                viewMoreDetails={viewMoreDetails}
              />
            </div>

          </BlockBox>
        </div>
      </div>
      {
        <ViewMoreModal
          isOpen={viewMoreModal}
          handleClose={() => setViewMoreModal(false)}
          columns={tableHeadersViewMore}
          loading={isLoading}
          sections={["BASIC INFO", "CLUSTER INFO", "DNS SSL DETAILS"]}
          column={true}
          rows={viewMoreData || []}
        />
      }
    </div>
  );
};

export default EKS;
