import React, { useEffect, useState } from "react";
import axios from "axios";

import Stepper from "../../../Components/Element/Stepper/Stepper";
import SelectServer from "./Steps/SelectServer/SelectServer";
import SelectSecurityScripts from "./Steps/SelectSecurityScripts/SelectSecurityScripts";

const SelectSecurity = (props) => {
    const securityScripts = [
        {
            id: "mbss",
            name: "MBSS",
        }, {
            id: "access_control_over_edge",
            name: "Access Control Over Edge",
        }];
    const selectSecuritySteps = [
        {
            label: "Select Cluster for Micro Cloud Installation",
            content: (
                <SelectServer
                    setCheckBoxSelected={setServerSelected}
                    checkBoxSelected={serverSelected}
                    tableData={tableData}
                />
            ),
        },
        {
            label: "Select Security Script",
            content: (
                <SelectSecurityScripts
                    setCheckBoxSelected={setSecurityScriptSelected}
                    checkBoxSelected={securityScriptSelected}
                    tableData={securityScripts}
                    setLauncherManagementIP={setLauncherManagementIP}
                    launcherManagementIP={launcherManagementIP}
                />
            )
        },

    ];

    const [tableData, setTableData] = useState([]);
    const [serverSelected, setServerSelected] = useState([]);
    const [securityScriptSelected, setSecurityScriptSelected] = useState([]);
    const [launcherManagementIP, setLauncherManagementIP] = useState("");

    const getActiveClusterForMicroCloud = () => {
        axios
            .get("/api/v1/server", {
                params: {
                    isActive: true,
                    isWorker: true,
                },
            })
            .then((res) => {
                const data = res.data.data.map((cluster) => {
                    return {
                        id: cluster._id,
                    };
                });
                setTableData(res.data.data);
            });
    };

    function handleSecurityInstall() {

        let data = {};
        console.log(serverSelected)
        if (securityScriptSelected[0] === "access_control_over_edge") {
            data = {
                serverID: serverSelected[0],
                securityScript: securityScriptSelected[0],
                launcherManagementIP: launcherManagementIP
            };
        } else {
            data = {
                serverID: serverSelected[0],
                securityScript: securityScriptSelected[0],
            };
        }


        axios.post("/api/v1/server/installSecurity", data).then((res) => {
            console.log(res);
            props.setSnackbarOpen(true);
            props.setSnackbarData({
                message: "Installation of Security Script Started Successfully",
                severity: "success"
            });
        }).catch((err) => {
            console.log(err);
            props.setSnackbarOpen(true);
            props.setSnackbarData({ message: "Error in Installing Security Script", severity: "error" });
            return;
        });
        props.setOpen(false)
        console.log("Done");
    }

    useEffect(() => {
        getActiveClusterForMicroCloud();
    }, [securityScriptSelected]);

    return (
        <Stepper
            finishCallback={handleSecurityInstall}
            steps={selectSecuritySteps}
            height="auto"
            width="50em"
            currentMaxSteps={
                (serverSelected.length === 1) +
                (securityScriptSelected.length === 1) +
                1
            }
            setOpen={props.setOpen}
        />
    );
};

export default SelectSecurity;
