import { Grid, InputLabel, TextField } from '@mui/material'
import React from 'react'

function AzureCreds({ formFelids, handleFormChange }) {
    return (
        <Grid container spacing={4}>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    Subscription ID
                </InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="SubscriptionID"
                    value={formFelids.SubscriptionID}
                    onChange={handleFormChange}
                />
            </Grid>
            <Grid item>
                <InputLabel id="demo-simple-select-label">Client ID</InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="ClientID"
                    value={formFelids.ClientID}
                    onChange={handleFormChange}
                />
            </Grid>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    Client Secret
                </InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="ClientSecret"
                    value={formFelids.ClientSecret}
                    onChange={handleFormChange}
                />
            </Grid>
            <Grid item>
                <InputLabel id="demo-simple-select-label">Tenant ID</InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="TenantID"
                    value={formFelids.TenantID}
                    onChange={handleFormChange}
                />
            </Grid>
        </Grid>
    )
}

export default AzureCreds