import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
    Alert,
    Snackbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import StepContent from "@mui/material/StepContent";

import AzureCreds from "./steps/AzureCreds";
import BasicInfo from "./steps/ResourceGroupInfo";
import VMSizes from "./vmSizes.json";
import DNSSSL from "./steps/DNSSSL";
import ClusterInfo from "./steps/clusterInfo";

const steps = ["Azure Creds", "Resource Group Info", "Cluster Info", "DNS and SSL Details"];

const WizardForm = ({ activeStep, setFormFelids, formFelids }) => {
    const handleFormChange = (e) => {
        setFormFelids({ ...formFelids, [e.target.name]: e.target.value });
    };
    const renderStepComponent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <AzureCreds formFelids={formFelids} setFormFelids={setFormFelids} handleFormChange={handleFormChange} />
                );
            case 1:
                return (
                    <BasicInfo formFelids={formFelids} setFormFelids={setFormFelids} VMSizes={VMSizes} handleFormChange={handleFormChange} />
                );
            case 2:
                return (
                    <ClusterInfo formFelids={formFelids} setFormFelids={setFormFelids} VMSizes={VMSizes} handleFormChange={handleFormChange} />
                );
            case 3:
                return (
                    <DNSSSL formFelids={formFelids} setFormFelids={setFormFelids} handleFormChange={handleFormChange} />
                );
            default:
                return <div>{activeStep + 1}</div>;
        }
    };

    return <React.Fragment>{renderStepComponent()}</React.Fragment>;
};

export default function AKSForm({ propsData }) {
    const location = useLocation();
    const navigate = useNavigate();
    const ClusterToBeUpdated = location.pathname.split('/')[4]

    const stepperInputs = [
        ["SubscriptionID", "ClientID", "ClientSecret", "TenantID"],
        ["Location", "ResourceGroupName", "ResourceGroupPresent"],
        ["VMSize", "ClusterName"],
        ["companyName", "DNSHostname", "BundledCERT", "CERTKey"],
    ];
    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#FFC000",
            },
        },
    });

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [formFelids, setFormFelids] = React.useState({
        SubscriptionID: "",
        ClientID: "",
        ClientSecret: "",
        TenantID: "",
        ResourceGroupPresent: false,
        Location: "",
        ResourceGroupName: "",
        ClusterName: "",
        VMSize: "",
        DNSHostname: "",
        companyName: "",
        BundledCERT: "",
        CERTKey: "",
        type: location.state.type,
        requestId: location.state.requestId,
    });

    React.useEffect(() => {
        setFormFelids({
            SubscriptionID: propsData.SubscriptionID || "",
            ClientID: propsData.ClientID || "",
            ClientSecret: propsData.ClientSecret || "",
            TenantID: propsData.TenantID || "",
            ResourceGroupPresent: propsData.ResourceGroupPresent || false,
            Location: propsData.Location || "",
            ResourceGroupName: propsData.ResourceGroupName || "",
            // ClusterName: propsData.ClusterName || "",
            companyName: propsData.companyName || "",
            VMSize: propsData.VMSize || "",
            DNSHostname: propsData.DNSHostname || "",
            BundledCERT: propsData.BundledCERT || "",
            CERTKey: propsData.CERTKey || "",
            type: propsData.type,
            requestId: propsData.requestId,
        });
    }, [propsData]);

    const [SnackBarOpen, setSnackBarOpen] = React.useState(false);
    const [SnackBarMessage, setSnackBarMessage] = React.useState(false);

    const isStepOptional = (step) => {
        return false;
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const handleNext = () => {
        if (
            stepperInputs[activeStep]
                .map((key) => formFelids[key] === "")
                .includes(true)
        ) {
            // console.log("Please fill all the fields");
            setSnackBarMessage("Please fill all the fields");
            setSnackBarOpen(true);
            return;
        }

        let newSkipped = skipped;

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const HandelFinish = () => {
        // check if all the fields are filled or not
        if (
            stepperInputs[activeStep]
                .map((key) => formFelids[key] === "")
                .includes(true)
        ) {
            setSnackBarMessage("Please fill all the fields");
            setSnackBarOpen(true);
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        const data = {
            SubscriptionID: formFelids.SubscriptionID,
            ClientID: formFelids.ClientID,
            ClientSecret: formFelids.ClientSecret,
            TenantID: formFelids.TenantID,
            Location: formFelids.Location,
            ResourceGroupName: formFelids.ResourceGroupName,
            ResourceGroupPresent: formFelids.ResourceGroupPresent,
            VMSize: formFelids.VMSize,
            // ClusterName: formFelids.ClusterName,
            companyName: formFelids.companyName,
            DNSHostname: formFelids.DNSHostname,
            BundledCERT: formFelids.BundledCERT,
            CERTKey: formFelids.CERTKey,
            type: propsData.type,
            requestId: propsData.requestId,
        };
        axios
            .put(`/api/v1/aks/${ClusterToBeUpdated}`, { data })
            .then((res) => {
                navigate("/launcher/aks", {
                    state: {
                        value: "1",
                    },
                });
                return;
            })
            .catch((err) => {
                return;
            });
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const handleSnackBarClose = (event, reason) => {
        setSnackBarOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Snackbar
                open={SnackBarOpen}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleSnackBarClose} severity="error">
                    {SnackBarMessage}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: "100%",
                    bgcolor: "#fff",
                    minHeight: "85vh",
                }}
            >
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    style={{
                        backgroundColor: "#fff",
                        padding: "40px",
                    }}
                >
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            color: "#000",
                                        }}
                                    >
                                        {label}
                                    </Typography>
                                </StepLabel>
                                <StepContent>
                                    <React.Fragment>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                p: 8,
                                            }}
                                        >
                                            <WizardForm
                                                activeStep={activeStep}
                                                setFormFelids={setFormFelids}
                                                formFelids={formFelids}
                                            />
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                            <Button
                                                color="inherit"
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                            <Box sx={{ flex: "1 1 auto" }} />
                                            {isStepOptional(activeStep) && (
                                                <Button
                                                    color="inherit"
                                                    onClick={handleSkip}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Skip
                                                </Button>
                                            )}

                                            {activeStep === steps.length - 1 ? (
                                                <Button variant="contained" onClick={HandelFinish}>
                                                    Finish
                                                </Button>
                                            ) : (
                                                <Button variant="contained" onClick={handleNext}>
                                                    Next
                                                </Button>
                                            )}
                                        </Box>
                                    </React.Fragment>
                                </StepContent>
                            </Step>
                        );
                    })}
                    {activeStep === steps.length && (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    )}
                </Stepper>
            </Box>
        </ThemeProvider>
    );
}
