import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import requestSlice from "./slice/requestSlice";
import clusterSlice from "./slice/clusterSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    request: requestSlice,
    cluster: clusterSlice,
  },
});
