import React, { useEffect, useState } from "react";
import axios from "axios";

import StyledButton from "../../../Components/Element/Button/Button";
import Table from "../../../Components/Element/Table/Table";
import "./OSTable.css";
import Switch from "../../../Components/Element/Switch/Switch";
import ConfirmDialog from "../../../Components/Element/Dialog/Dialog";

const tableHeaders = [
    { name: "CheckAll", type: "checkbox", editable: true, key: "id" },
    { name: "BMC IP", type: "label", key: "bmcip" },
    { name: "Management IP", type: "label", key: "managementip" },
    { name: "Data IP", type: "label", key: "dataip" },
    { name: "Username", type: "label", key: "username" },
    { name: "Password", type: "label", key: "password" },
];

const OSTable = (props) => {
    const [tableData, setTableData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [checkBoxSelected, setCheckBoxSelected] = useState([])
    const [forceInstallValue, setForceInstallValue] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)

    const getActiveServersForOSInstalltion = () => {
        axios.get("/api/v1/server",
            {
                params: {
                    isActive: true,
                }
            }).then(res => {
                const data = res.data.data.map((item) => {
                    return {
                        ...item,
                        id: item._id,
                    };
                })

                const filteredData = data.filter(item => item.osInstallationStatus === "inactive")


                setTableData(filteredData);
                setAllData(data);
            });
    };

    const handleFilter = () => {

        if (!forceInstallValue) {
            setTableData(allData.filter(server => server.osInstallationStatus === "inactive"))
        } else {
            setTableData(allData)
        }
    }
    const handleClose = () => {
        setDialogOpen(!dialogOpen);
    };

    const handleSubmit = () => {
        setDialogOpen(false)

        if (checkBoxSelected.length === 0) return;
        axios.post("/api/v1/server/installOS", { servers: checkBoxSelected, forceInstallValue }).then((res) => {
            props.setSnackbarOpen(true)
            props.setSnackbarData({
                message: "OS Installation started Successfully",
                severity: "success"
            })
            console.log(res.data);
        }).catch((err) => {
            props.setSnackbarOpen(true)
            props.setSnackbarData({
                message: err.message,
                severity: "error"
            })
            return;
        });
        props.toggleOpenState();
    }

    useEffect(() => {
        getActiveServersForOSInstalltion();
    }, []);
    useEffect(() => {
        handleFilter()
        setCheckBoxSelected([])
    }, [forceInstallValue])

    return (
        <div className="csv-main">
            <div className="csv-headers">
                <div className="csv-title">Install OS</div>
                <div className="csv-closeX" onClick={props.toggleOpenState}>X</div>
            </div>
            <div className="csv-box reverse">
                <Switch value={forceInstallValue} setValue={setForceInstallValue} setForce label={"Forceful Install"} />
            </div>
            <div className="csv-box csv-table">
                <div className="csv-table-cover">
                    <Table
                        tableStyle={{ fontsize: "12px" }}
                        column={true}
                        customTableMessage={"No Active OS Found to Install Server"}
                        columns={tableHeaders}
                        rows={tableData}
                        setTableServerData={setTableData}
                        setCheckBoxData={setCheckBoxSelected}
                        checkboxData={checkBoxSelected}
                    />
                </div>
            </div>
            <ConfirmDialog open={dialogOpen} handleClose={handleClose} handleSubmit={handleSubmit} title={"Alert!!! Forced Update"} content={"Please beaware that all the data will be lost"} />
            <div className="csv-box csv-mid">
                <div className="csv-button-top">
                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "white",
                            bghover: "#881630",
                            colorhover: "white",
                            color: "#881630",
                            border: "2px solid #881630"
                        }}
                        name={"Cancel"}
                        onClickFunc={props.toggleOpenState}
                    />
                    <StyledButton
                        styleDetails={{
                            padding: "9px 15px",
                            borderRadius: "10px",
                            bgcolor: "#881630",
                            bghover: "#ffc000",
                        }}
                        disabled={checkBoxSelected.length === 0}
                        onClickFunc={forceInstallValue ? handleClose : handleSubmit}
                        name={"Install OS"}
                    />
                </div>
            </div>
        </div>
    );
};

export default OSTable;
