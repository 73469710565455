import React from "react";
import { useLocation } from "react-router-dom";

import "./CreateAksCluster.css";
import AKSForm from "./AksForm/AksForm";

const CreateAksCluster = () => {
  const location = useLocation();
  return (
    <div> <AKSForm /></div>
  );
};

export default CreateAksCluster;
