import React, { useEffect, useState } from "react";
import './ClusterInfo.css'
import BlockBox from "../../../Components/Element/Box/Box";

import StyledButton from "../../../Components/Element/Button/Button";
import SnackbarComponent from "../../../Components/Element/Snackbar/Snackbar";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../Components/Element/Dialog/Dialog";

const AksClusterInfo = () => {

    const navigate = useNavigate()

    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarData, setSnackbarData] = useState({ message: "", severity: "success" })
    const [dialogOpen, setDialogOpen] = useState(false)

    const [clusterData, setClusterData] = useState({})

    const handleGoBack = () => {
        navigate("/launcher/aks")
    }

    const handleDelete = () => {
        const clusterToBeDeleted = clusterData.type;
        axios.delete(`/api/v1/${clusterToBeDeleted.toLowerCase()}/${clusterData._id}`).then((res) => {
            setSnackbarData({ message: "Cluster Deleted Successfully", severity: "success" })
            setSnackbarOpen(true)
            navigate("/launcher/aks")
        }).catch((err) => {
            setSnackbarData({ message: "Error Deleting Cluster", severity: "error" })
            setSnackbarOpen(true)
        })
        setDialogOpen(!dialogOpen);
    };

    useEffect(() => {
        const clusterId = window.location.pathname.split("/")[3];
        axios.get("/api/v1/aks/" + clusterId).then((res) => {

            setSnackbarData({ message: "Data Fetched Successfully", severity: "success" })
            setSnackbarOpen(true)
            setClusterData(res.data.data);
        })
    }, [])


    return (
        <div>
            <SnackbarComponent open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarData.message}
                severity={snackbarData.severity} />
            <ConfirmDialog open={dialogOpen} handleClose={handleDelete} handleSubmit={handleDelete}
                title={"Alert!!! Delete"}
                content={"Please beware that all the data will be lost and the cluster will be deleted"} />
            <div className="server-main">
                <div className="server-box">
                    <BlockBox>
                        <div className="flex-box center">
                            <StyledButton
                                name={"Delete Cluster"}
                                onClickFunc={handleDelete}
                                styleDetails={{
                                    padding: "9px 25px",
                                    borderRadius: "5px",
                                    bgcolor: "red",
                                    bghover: "#ffc000",
                                    margin: "0 1em",
                                }}
                            />
                            <StyledButton
                                name={"Go Back"}
                                onClickFunc={handleGoBack}
                                styleDetails={{
                                    padding: "9px 25px",
                                    borderRadius: "5px",
                                    bgcolor: "#881630",
                                    bghover: "#ffc000",
                                    margin: "0 1em",
                                }}
                            />
                        </div>
                    </BlockBox>
                    <h3>Cluster Info</h3>
                    <BlockBox>
                        <div className="csv-box csv-table">
                            <div className="csv-table-cover">
                                {clusterData &&
                                    <div className="cluster-details">
                                        <div className="server-item">
                                            <label className="server-item-name">Cluster Name</label>
                                            <p className="server-item-details">{clusterData.ResourceGroupName}</p>
                                        </div>

                                        <div className="server-item">
                                            <label className="server-item-name">Location</label>
                                            <p className="server-item-details">{clusterData.Location}</p>
                                        </div>

                                        <div className="server-item">
                                            <label className="server-item-name">VM Size</label>
                                            <p className="server-item-details">{clusterData.VMSize}</p>
                                        </div>


                                        <div className="server-item">
                                            <label className="server-item-name">Status</label>
                                            <p className="server-item-details">{clusterData.status === 'Active' ? "Active" : "Inactive"}</p>
                                        </div>
                                    </div>
                                }

                            </div>

                        </div>


                    </BlockBox>

                </div>
            </div>
        </div>
    )
}

export default AksClusterInfo;
