import React, { useEffect, useState } from "react";
import SpringModal from "../Modal/Modal";
import "./ModalDetails.css";
import Table from "../Table/Table";
import SnackbarComponent from "../Snackbar/Snackbar";
import axios from "axios";
import { getEksClusterData, getAksClusterData, refreshClusterData } from "../../../slice/clusterSlice";
import { useDispatch, useSelector } from "react-redux";
import { CancelOutlined, CheckCircleOutlined, Download, VisibilityOutlined, CloseIcon, Add, Close, Height } from "@mui/icons-material";
import AddWorker from "../../../Page/AddEdge/AddWorker/AddWorker";
import AddWorkerToCluster from "../../../Page/AddEdge/AddWorkerToCluster/AddWorkerToCluster";
import StyledButton from "../Button/Button";
import AddIcon from "@mui/icons-material/Add";
import Csvtable from "../../../Page/AddEdge/CsvTable/Csvtable";
import OSTable from "../../../Page/AddEdge/OSTable/OSTable";


function ModalDetails({ open, handleClose, eksData }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    severity: "success",
  });
  const [workerData, setWorkerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddEdgeModel, setOpenAddEdgeModel] = useState(false);
  const [openAddWorkerModel, setOpenAddWorkerModel] = useState(false);
  const [clusterData, setClusterData] = useState([]);
  const [openCSVModel, setOpenCSVModel] = useState(false);
  const [openOSModel, setOpenOSModel] = useState(false);

  const handleOSToggle = () => {
    setOpenOSModel(!openOSModel);
  }

  const dispatch = useDispatch();


  const handleAddEdgeToggle = () => {
    setOpenAddEdgeModel(!openAddEdgeModel);
    getClustersData();
  };
  const handleAddWorkerToggle = () => {
    setOpenAddWorkerModel(!openAddWorkerModel);
    getDataForWorkerNode();
  };

  const getDataForWorkerNode = () => {
    setIsLoading(true);
    Promise.all([
      axios.get("/api/v1/eks"),
      axios.get("/api/v1/aks")
    ]).then(([eksRes, aksRes]) => {
      const eksData = eksRes.data.data;
      const aksData = aksRes.data.data;
      // Assuming worker data is different for EKS and AKS, adjust accordingly
      setWorkerData([...eksData, ...aksData]);
      setIsLoading(false);
    }).catch(error => {
      console.error("Error fetching worker node data:", error);
      setIsLoading(false);
    });
  };

  const getClustersData = () => {
    setIsLoading(true);
    Promise.all([
      axios.get("/api/v1/eks"),
      axios.get("/api/v1/aks")
    ]).then(([eksRes, aksRes]) => {
      const eksData = eksRes.data.data;
      const aksData = aksRes.data.data;
      setClusterData([...eksData, ...aksData]);
      setIsLoading(false);
    }).catch(error => {
      console.error("Error fetching cluster data:", error);
      setIsLoading(false);
    });
  };
  const tableHeadersEks = [
    { name: "Org Name", type: "label", key: "orgName" },
    { name: "Region", type: "label", key: "region" },
    { name: "Instance Type", type: "label", key: "instanceType" },
    {
      name: "Primary Storage",
      suffix: "GB",
      type: "label",
      key: "primaryStorage",
    },
    {
      name: "Secondary Storage",
      suffix: "GB",
      type: "label",
      key: "secondaryStorage",
    },
    { name: "Elastic IP", type: "label", key: "elastic_ip" },
    // { name: "Action", type: "action", key: "pem_key" },
    // { name: "Download", type: "download" },
  ];

  const tableHeadersAks = [
    { name: "Resource Group Name", type: "label", key: "ResourceGroupName" },
    { name: "Location", type: "label", key: "Location" },
    { name: "Vm size", type: "label", key: "VMSize" },
    { name: "Elastic IP", type: "label", key: "elastic_ip" },
    // { name: "Action", type: "action", key: "pem_key" },
    // { name: "Download", type: "download" },
  ];

  const headers =
    eksData && eksData.length > 0 && eksData[0].type === "EKS"
      ? tableHeadersEks
      : tableHeadersAks;
  const modalType = eksData && eksData.length > 0 && eksData[0].type === "EKS" > 0 ? "EKS" : "AKS"

  const handleCSVToggle = () => {
    setOpenCSVModel(!openCSVModel);
  }


  return (
    <div className="custom-modal-container">
      <SpringModal styleModal={{
        height: "70vh"
      }
      } open={open}>
        <div className="custom-modal-header">
          <div className="custom-close-btn" onClick={handleClose}>
            <Close />
          </div>
        </div>
        <div className="custom-modal-body">

          <div className="custom-table-container">
            <table className="custom-table-main">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header.key}>{header.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="custom-tableBody">
                {eksData.map((data, index) => (
                  <tr key={index}>
                    {headers.map((header) =>
                      <td style={{ textAlign: "center" }} key={header.key}>
                        {data[header.key]}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="add-edge-buttons">
            <StyledButton
              styleDetails={{
                padding: "9px 15px",
                borderRadius: "10px",
                bgcolor: "#881630",
                bghover: "#ffc000",
                margin: "0px 5px"
              }}
              name={"Add New Servers"}
              endIcon={<AddIcon />}
              onClickFunc={handleCSVToggle}
            />

            <StyledButton
              styleDetails={{
                padding: "9px 15px",
                borderRadius: "10px",
                bgcolor: "#881630",
                bghover: "#ffc000",
                margin: "0px 5px"
              }}
              name={"Add OS"}
              endIcon={<AddIcon />}
              onClickFunc={handleOSToggle}
            />
            <StyledButton
              styleDetails={{
                padding: "9px 15px",
                borderRadius: "10px",
                bgcolor: "#881630",
                bghover: "#ffc000",
                margin: "0px 5px"
              }}
              name={"Add Worker Node"}
              endIcon={<Add />}
              onClickFunc={handleAddWorkerToggle}
            />
            <StyledButton
              styleDetails={{
                padding: "9px 15px",
                borderRadius: "10px",
                bgcolor: "#881630",
                bghover: "#ffc000",
                margin: "0px 5px"
              }}
              name={"Add Edge"}
              endIcon={<Add />}
              onClickFunc={handleAddEdgeToggle}
            />
          </div>

        </div>
      </SpringModal>
      <SpringModal styleModal={{ width: "80vw", lgWidth: "100vw", mdWidth: "97vw" }} open={openCSVModel}
        setOpen={setOpenCSVModel}>
        <Csvtable toggleOpenState={handleCSVToggle} setSnackbarOpen={setSnackbarOpen}
          setSnackbarData={setSnackbarData} />
      </SpringModal>
      <SpringModal open={openAddEdgeModel}>
        <AddWorkerToCluster setOpen={setOpenAddEdgeModel} setSnackbarOpen={setSnackbarOpen} setSnackbarData={setSnackbarData} modalType={modalType} />
      </SpringModal>
      <SpringModal open={openAddWorkerModel}>
        <AddWorker setOpen={setOpenAddWorkerModel} setSnackbarOpen={setSnackbarOpen} setSnackbarData={setSnackbarData} modalType={modalType} />
      </SpringModal>
      <SpringModal styleModal={{ width: "80vw", lgWidth: "80vw", mdWidth: "97vw" }} open={openOSModel}
        setOpen={setOpenOSModel}>
        <OSTable toggleOpenState={handleOSToggle} setSnackbarOpen={setSnackbarOpen}
          setSnackbarData={setSnackbarData} />
      </SpringModal>
    </div >



  );
}

export default ModalDetails;
