import React from 'react'
import './Box.css'

const BlockBox = (props) => {
  return (
    <div className='BlockBox-main' style={{margin:"1em 0"}}>
        {props.children}
    </div>
  )
}

export default BlockBox
