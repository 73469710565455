import React, { useState } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { CachedOutlined } from "@mui/icons-material";

import SnackbarComponent from "../../Components/Element/Snackbar/Snackbar";
import SpringModal from "../../Components/Element/Modal/Modal";
import BlockBox from "../../Components/Element/Box/Box";
import Searchbar from "../../Components/Element/Searchbar/Searchbar";
import StyledButton from "../../Components/Element/Button/Button";
import Table from "../../Components/Element/Table/Table";
import SelectSecurity from "./SelectSecurity/SelectSecurity";

const tableHeaders = [
    { name: "Management IP", type: "label", key: "managementip" },
    { name: "Hostname", type: "label", key: "hostname" },
    { name: "MBSS", type: "bool", key: "mbss" },
    { name: "Access Control at Edge", type: "bool", key: "accessControl" },
    { name: "LUKS", type: "bool", key: "luks" },
]
const Security = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openSecurityModel, setOpenSecurityModel] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarData, setSnackbarData] = useState({ message: "", severity: "success" })
    const getData = async () => {
        setIsLoading(true);
        axios.get("/api/v1/server")
            .then((res) => {
                setTableData(res.data.data);
                setIsLoading(false);
            });
    };

    const handleAddSecurityToggle = () => {
        setOpenSecurityModel(!openSecurityModel);
        getData();
    };

    return (
        <>
            <SnackbarComponent open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarData.message}
                severity={snackbarData.severity} />
            <SpringModal open={openSecurityModel}>
                <SelectSecurity setOpen={setOpenSecurityModel} setSnackbarOpen={setSnackbarOpen}
                    setSnackbarData={setSnackbarData} />
            </SpringModal>
            <div className="server-main">
                <div className="server-box">
                    <BlockBox>
                        <div className="server-block-header">
                            <div className="server-box-title">Add Security</div>
                            <div className="server-block-header-right">
                                <div style={{ marginRight: "15px" }}>
                                    <Searchbar />
                                </div>

                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px",
                                    }}
                                    name={"Add Security"}
                                    endIcon={<AddIcon />}
                                    onClickFunc={handleAddSecurityToggle}
                                />
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0px 5px",
                                    }}
                                    name={"Refresh Status"}
                                    endIcon={<CachedOutlined />}
                                    onClickFunc={getData}
                                />
                            </div>
                        </div>

                        <div className="server-table-cover">
                            <Table
                                columns={tableHeaders}
                                loading={isLoading}
                                customTableMessage={"No Servers Found"}
                                column={true}
                                rows={tableData}
                            />
                        </div>
                    </BlockBox>
                </div>
            </div>
        </>
    )
}
export default Security