import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {FormControlLabel, Switch} from "@mui/material";

const SwitchToggle = (props) => {
    const [value, setValue] = React.useState(props.value || false);

    const handleChange = (event) => {
        setValue(event.target.checked);
        props.setValue(event.target.checked);
    };

    return (
        <div>
            <Box style={{ width: "100%" }}>
                <FormControl fullWidth>
                    <FormControlLabel control={<Switch checked={value} onChange={handleChange}/>} label={props.label}/>
                </FormControl>
            </Box>
        </div>
    );
}

export default SwitchToggle;
