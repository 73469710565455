import React from 'react'

function RequestData({data}) {
    return (
        <div className="csv-box csv-table">
            <div className="csv-table-cover">
                <div className="server-item">
                    <label className="server-item-name">Catalogoue</label>
                    <p className="server-item-details">{data.catalogue}</p>
                </div>
                <div className="server-item">
                    <label className="server-item-name">Location</label>
                    <p className="server-item-details">{data.location}</p>
                </div>
                <h4>Client Details</h4>
                <div className="server-item">
                    <label className="server-item-name">Name</label>
                    <p className="server-item-details">
                        {data.proposalId.endCustomerDetails.user.name}
                    </p>
                </div>

                <div className="server-item">
                    <label className="server-item-name">Organization Name</label>
                    <p className="server-item-details">
                        {data.proposalId.endCustomerDetails.organization.name}
                    </p>
                </div>

                <div className="server-item">
                    <label className="server-item-name">Email</label>
                    <p className="server-item-details">
                        {data.proposalId.endCustomerDetails.user.email}
                    </p>
                </div>

                <div className="server-item">
                    <label className="server-item-name">
                        Credential Intent to Share
                    </label>
                    <p className="server-item-details">
                        {data.credIntent ? "Yes" : "No"}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RequestData