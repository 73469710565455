import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'

function ClusterInfo({ formFelids, handleFormChange, VMSizes }) {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item>
                    <InputLabel>VM Size</InputLabel>
                    <Select
                        sx={{ minWidth: 180 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formFelids.VMSize}
                        name="VMSize"
                        onChange={handleFormChange}
                        required
                    >
                        {VMSizes.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item>
                    <InputLabel id="demo-simple-select-label">
                        Cluster Name
                    </InputLabel>
                    <TextField
                        required
                        id="outlined-basic"
                        variant="outlined"
                        name="ClusterName"
                        value={formFelids.ClusterName}
                        onChange={handleFormChange}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ClusterInfo