import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    eks_cluster_data: [],
    aks_cluster_data: [],
    loading: false,
};

export const getEksClusterData = createAsyncThunk("getEksClusterData", async () => {
    try {
        const response = await axios.get("/api/v1/eks");
        return response.data.data.map((item) => ({
            ...item,
            elastic_ip: item.elastic_ip ? item.elastic_ip : "Not Assigned",
            pem_key: item.pem_key ? item.pem_key : "Not Assigned",
            type: "EKS",
        }));
    } catch (error) {
        return [];
    }
});

export const getAksClusterData = createAsyncThunk("getAksClusterData", async () => {
    try {
        const response = await axios.get("/api/v1/aks");
        return response.data.data.map((item) => ({
            ...item,
            elastic_ip: item.elastic_ip ? item.elastic_ip : "Not Assigned",
            pem_key: item.pem_key ? item.pem_key : "Not Assigned",
            type: "AKS",
        }));
    } catch (error) {
        return [];
    }
});


export const refreshEksClusterData = createAsyncThunk("refreshEksClusterData", async () => {
    try {
        const response = await axios.get("/api/v1/eks");
        return response.data.data.map((item) => ({
            ...item,
            elastic_ip: item.elastic_ip ? item.elastic_ip : "Not Assigned",
            pem_key: item.pem_key ? item.pem_key : "Not Assigned",
            type: "EKS",
        }));
    } catch (error) {
        return [];
    }
});

export const refreshAksClusterData = createAsyncThunk("refreshAksClusterData", async () => {
    try {
        const response = await axios.get("/api/v1/aks");
        return response.data.data.map((item) => ({
            ...item,
            elastic_ip: item.elastic_ip ? item.elastic_ip : "Not Assigned",
            pem_key: item.pem_key ? item.pem_key : "Not Assigned",
            type: "AKS",
        }));
    } catch (error) {
        return [];
    }
});
export const clusterSlice = createSlice({
    name: "cluster",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEksClusterData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getEksClusterData.fulfilled, (state, action) => {
                state.loading = false;
                state.eks_cluster_data = action.payload.reverse();
            })
            .addCase(getEksClusterData.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getAksClusterData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAksClusterData.fulfilled, (state, action) => {
                state.loading = false;
                state.aks_cluster_data = action.payload.reverse();
            })
            .addCase(getAksClusterData.rejected, (state) => {
                state.loading = false;
            })
            .addCase(refreshEksClusterData.pending, (state) => {
                state.loading = true;
            })
            .addCase(refreshEksClusterData.fulfilled, (state, action) => {
                state.loading = false;
                state.eks_cluster_data = action.payload.reverse();
            })
            .addCase(refreshEksClusterData.rejected, (state) => {
                state.loading = false;
            })
            .addCase(refreshAksClusterData.pending, (state) => {
                state.loading = true;
            })
            .addCase(refreshAksClusterData.fulfilled, (state, action) => {
                state.loading = false;
                state.aks_cluster_data = action.payload.reverse();
            })
            .addCase(refreshAksClusterData.rejected, (state) => {
                state.loading = false;
            });
    },
});

export default clusterSlice.reducer;
