import React from 'react'
import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

function RenderStep4({ formFelids, handleFormChange, source }) {
    return (
        <Grid container spacing={4}>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    Company Name
                </InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="companyName"
                    type="text"
                    value={formFelids.companyName}
                    onChange={handleFormChange}
                    disabled={source === 'marketplace'}
                />
            </Grid>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    DNS Host Name
                </InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    type="text"
                    label=""
                    variant="outlined"
                    name="dnsHostName"
                    value={formFelids.dnsHostName}
                    onChange={handleFormChange}
                    disabled={source === 'marketplace'}

                />
            </Grid>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    Bundled CERT
                </InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="bundledCert"
                    type="text"
                    value={formFelids.bundledCert}
                    onChange={handleFormChange}
                    disabled={source === 'marketplace'}
                    multiline
                    rows={4}
                />
            </Grid>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    CERT Key
                </InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    type="text"
                    label=""
                    variant="outlined"
                    name="certKey"
                    value={formFelids.certKey}
                    onChange={handleFormChange}
                    disabled={source === 'marketplace'}
                    multiline
                    rows={4}
                />
            </Grid>
        </Grid>
    )
}

export default RenderStep4