import React from "react";
import { useLocation } from "react-router-dom";

import "./CreateEksCluster.css";
import EKSClusterForm from "./EksForm/EksForm";


const CreateEksCluster = () => {
  const location = useLocation();
  return (
    <div> <EKSClusterForm /> </div>
  );
};

export default CreateEksCluster;
