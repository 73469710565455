import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";

export default function SpringModal(props) {
	const StyledBox = styled(Box)(({ theme }) => ({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: `${props.styleModal ? props.styleModal.width : "fit-content"}`,
		height: `${props.styleModal ? props.styleModal.height : "fit-content"}`,
		background: theme.palette.background.paper,
        borderRadius:"3px",
        overflow:"hidden",
		boxShadow: 24,
		p: 4,
		[theme.breakpoints.down("lg")]: {
			height: `${props.styleModal ? props.styleModal.lgHeight : "fit-content"}`,
		},
		[theme.breakpoints.down("md")]: {
			width: `${props.styleModal ? props.styleModal.mdHeight : "fit-content"}`,
		},
		maxHeight: "100vh",
		[theme.breakpoints.down("lg")]: {
            width: `${props.styleModal ? props.styleModal.lgWidth : "fit-content"}`,
		},
		[theme.breakpoints.down("md")]: {
            width: `${props.styleModal ? props.styleModal.mdWidth : "fit-content"}`,
		}
	}));
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={props.open}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={props.open}>
					<StyledBox>{props.children}</StyledBox>
				</Fade>
			</Modal>
		</div>
	);
}
