import React, { useState } from "react";

import BlockBox from "../../../../../Components/Element/Box/Box";
import Table from "../../../../../Components/Element/Table/Table";
import Input from "../../../../../Components/Element/Input/Input";

const tableHeaders = [
    { name: "", type: "checkbox", editable: true, key: "id", wid: 10 },
    { name: "Name", type: "label", key: "name" },
];

const SelectSecurityScripts = (props) => {

    return (
        <BlockBox>
            <div className="MasterServer-step-main">
                <Table
                    columns={tableHeaders}
                    loading={false}
                    column={true}
                    rows={props.tableData}
                    customTableMessage={"No Compatible Script Available"}
                    setCheckBoxData={props.setCheckBoxSelected}
                    checkboxData={props.checkBoxSelected}
                    maxCheck={1}
                />
                <div className="clusterDetails-step-main">
                    {props.checkBoxSelected[0] === "access_control_over_edge" ? <div className="clusterdetails-input" style={{ margin: "1em" }}>
                        <Input
                            styled={{
                                padding: "10px 5px",
                                borderRadius: "5px",
                                margin: "10px 0 0 0",
                            }}
                            name={"Enter Launcher Management IP"}
                            value={props.launcherManagementIP}
                            placeholder={"eg: 10.20.20.103"}
                            callback={props.setLauncherManagementIP}
                        />
                    </div> : ""}
                </div>
            </div>
        </BlockBox>
    )

}

export default SelectSecurityScripts