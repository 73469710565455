import React from "react";
import "./TextBox.css";
import ListItemIcon from "@mui/material/ListItemIcon";

const Searchbar = (props) => {
	const BoxStyle = {
		borderRadius:`${props.styled?props.styled.borderRadius:"0"}`,
		margin:`${props.styled?props.styled.margin:"0"}`,
		border:`${props.styled?props.styled.border:"1px solid"}`,
	}
	const LabelStyle = {
		margin:`${props.labelstyled?props.labelstyled.margin:"0"}`,
	}
	const InputStyle = {
    width:"100%",
		padding:`${props.styled?props.styled.padding:"0"}`,
    background:"none"
	}
  return (
    <>
      {props.name && <label style={LabelStyle} htmlFor={props.id}>{props.name}</label>}
      <div style={BoxStyle} className="input-main">
        {props.icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: "center",
            }}
          >
            {props.icon}
          </ListItemIcon>
        )}

        <textarea
          style={InputStyle}
          id={props.id}
		  rows={props.rows || 5}
          placeholder={props.placeholder}
          onChange={(e) => props.callback(e.target.value)}
        />
      </div>
    </>
  );
};

export default Searchbar;
