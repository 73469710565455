import React, {useEffect, useState} from "react";
import './ClusterInfo.css'
import BlockBox from "../../../Components/Element/Box/Box";

import StyledButton from "../../../Components/Element/Button/Button";
import SnackbarComponent from "../../../Components/Element/Snackbar/Snackbar";

import axios from "axios";
import {useNavigate} from "react-router-dom";
import ConfirmDialog from "../../../Components/Element/Dialog/Dialog";

const EksClusterInfo = () => {

    const navigate = useNavigate()

    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarData, setSnackbarData] = useState({message: "", severity: "success"})
    const [dialogOpen, setDialogOpen] = useState(false)

    const [clusterData, setClusterData] = useState({})

    const handleGoBack = () => {
        navigate("/launcher/eks")
    }

    const handleDelete = () => {
        // console.log(clusterData,"delete")
        const clusterToBeDeleted= clusterData.type;
        axios.delete(`/api/v1/${clusterToBeDeleted.toLowerCase()}/${clusterData._id}`).then((res) => {
            setSnackbarData({message: "Cluster Deleted Successfully", severity: "success"})
            setSnackbarOpen(true)
            navigate("/launcher/eks")
        }).catch((err) => {
            setSnackbarData({message: "Error Deleting Cluster", severity: "error"})
            setSnackbarOpen(true)
        })
        setDialogOpen(!dialogOpen);
    };
    
    useEffect(() => {
        const clusterId = window.location.pathname.split("/")[3];
        axios.get("/api/v1/eks/" + clusterId).then((res) => {

            setSnackbarData({message: "Data Fetched Successfully", severity: "success"})
            setSnackbarOpen(true)
            setClusterData(res.data.data);
        })
    }, [])
    

    return (
        <div>
            <SnackbarComponent open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarData.message}
                               severity={snackbarData.severity}/>
            <ConfirmDialog open={dialogOpen} handleClose={handleDelete} handleSubmit={handleDelete}
                           title={"Alert!!! Delete"}
                           content={"Please beware that all the data will be lost and the cluster will be deleted"}/>
            <div className="server-main">
                <div className="server-box">
                    <BlockBox>
                        <div className="flex-box center">
                            <StyledButton
                                name={"Delete Cluster"}
                                onClickFunc={handleDelete}
                                styleDetails={{
                                    padding: "9px 25px",
                                    borderRadius: "5px",
                                    bgcolor: "red",
                                    bghover: "#ffc000",
                                    margin: "0 1em",
                                }}
                            />
                            <StyledButton
                                name={"Go Back"}
                                onClickFunc={handleGoBack}
                                styleDetails={{
                                    padding: "9px 25px",
                                    borderRadius: "5px",
                                    bgcolor: "#881630",
                                    bghover: "#ffc000",
                                    margin: "0 1em",
                                }}
                            />
                        </div>
                    </BlockBox>
                    <h3>Cluster Info</h3>
                    <BlockBox>
                        <div className="csv-box csv-table">
                            <div className="csv-table-cover">
                                {clusterData && Object.keys(clusterData).length > 0 && (
                                        <>
                                            <div className="server-item">
                                                <label className="server-item-name">Cluster Name</label>
                                                <p className="server-item-details">{clusterData.name}</p>
                                            </div>

                                            <div className="server-item">
                                                <label className="server-item-name">Instance Type</label>
                                                <p className="server-item-details">{clusterData.instanceType}</p>
                                            </div>

                                            <div className="server-item">
                                                <label className="server-item-name">Elastic IP</label>
                                                <p className="server-item-details">{clusterData.elastic_ip}</p>
                                            </div>

                                            <div className="server-item">
                                                <label className="server-item-name">Region</label>
                                                <p className="server-item-details">{clusterData.region}</p>
                                            </div>

                                            <div className="server-item">
                                                <label className="server-item-name">Organization Name</label>
                                                <p className="server-item-details">{clusterData.orgName}</p>
                                            </div>

                                            <div className="server-item">
                                                <label className="server-item-name">Primary Storage</label>
                                                <p className="server-item-details">{clusterData.primaryStorage}</p>
                                            </div>

                                            <div className="server-item">
                                                <label className="server-item-name">Secondary Storage</label>
                                                <p className="server-item-details">{clusterData.secondaryStorage}</p>
                                            </div>

                                            <div className="server-item">
                                                <label className="server-item-name">Server Status</label>
                                                <p className="server-item-details">{clusterData.status==='Active' ? "Active" : "Inactive"}</p>
                                            </div>
                                        </>
                                    )
                                }
                            </div>

                        </div>


                    </BlockBox>

                </div>
            </div>
        </div>
    )
}

export default EksClusterInfo;
