import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
    Alert,
    Snackbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import StepContent from "@mui/material/StepContent";

import awsRegion from "../../StaticDataJSON/awsRegion.json";
import InstanceType from "../../StaticDataJSON/instanceTypes.json";
import RenderStep0 from "./steps/Basic_Eks_Steps/RenderStep0";
import RenderStep1 from "./steps/Basic_Eks_Steps/RenderStep1";
import RenderStep2 from "./steps/Basic_Eks_Steps/RenderStep2";
import RenderStep3 from "./steps/Basic_Eks_Steps/RenderStep3";
import RenderStep4 from "./steps/Basic_Eks_Steps/RenderStep4";
import Marketplace_step_0 from "./steps/marketplace_Eks_Steps/marketplace_step_0";
import Marketplace_step_1 from "./steps/marketplace_Eks_Steps/marketplace_step_1";
import Marketplace_step_2 from "./steps/marketplace_Eks_Steps/marketplace_step_2";

const steps = ["Basic Info", "AWS hub Info", "Storage Info", "DNS and SSL Details"];
const marketplaceSteps = ["Basic Info", "AWS hub Info", "Storage Info"];


const WizardForm = ({ activeStep, setFormFelids, formFelids, source }) => {
    const handleFormChange = (e) => {
        setFormFelids({ ...formFelids, [e.target.name]: e.target.value });
    };
    const renderStepComponent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <RenderStep0 formFelids={formFelids} handleFormChange={handleFormChange} source={source} InstanceType={InstanceType} />
                );
            case 1:
                return (
                    <RenderStep2 formFelids={formFelids} handleFormChange={handleFormChange} awsRegion={awsRegion} />
                );
            case 2:
                return (
                    <RenderStep3 formFelids={formFelids} handleFormChange={handleFormChange} source={source} />
                );
            case 3:
                return (
                    <RenderStep4 formFelids={formFelids} handleFormChange={handleFormChange} source={source} />
                );
            default:
                return <div>{activeStep + 1}</div>;
        }
    };

    const renderMarketplaceStepComponent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Marketplace_step_0 formFelids={formFelids} handleFormChange={handleFormChange} source={source} InstanceType={InstanceType} />
                );
            case 1:
                return (
                    <Marketplace_step_1 formFelids={formFelids} handleFormChange={handleFormChange} awsRegion={awsRegion} />
                );

            case 2:
                return (
                    <Marketplace_step_2 formFelids={formFelids} handleFormChange={handleFormChange} source={source} />
                );
            default:
                return <div>{activeStep + 1}</div>;
        }
    };

    return <React.Fragment>{source === 'marketplace' ? renderMarketplaceStepComponent() : renderStepComponent()}</React.Fragment>;
};

export default function EKSClusterForm() {
    const location = useLocation();
    const navigate = useNavigate();

    const theme = createTheme({
        palette: {
            primary: {
                main: "#FFC000",
            },
        },
    });
    const source = location.state.source
    const currentSteps = location.state.source === 'marketplace' ? marketplaceSteps : steps
    const stepperInputs = [
        ["orgName", "instanceType"],
        ["awsAccessKey", "awsSecretKey", "region"],
        ["primaryStorage", "secondaryStorage"],
        ["companyName", "dnsHostName", "bundledCert", "certKey"],
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [SnackBarOpen, setSnackBarOpen] = React.useState(false);
    const [SnackBarMessage, setSnackBarMessage] = useState({
        message: "",
        severity: "success",
    });
    const [formFelids, setFormFelids] = React.useState({
        awsAccessKey: "",
        awsSecretKey: "",
        region: "",
        instanceType: location.state.source === 'marketplace' ? 't2.xlarge' : "",
        orgName: "",
        primaryStorage: location.state.source === 'marketplace' ? 30 : "",
        secondaryStorage: location.state.source === 'marketplace' ? 30 : "",
        type: location.state.type,
        requestId: location.state.requestId,
        companyName: "",
        dnsHostName: "",
        bundledCert: "",
        certKey: ""
    });

    const isStepOptional = (step) => {
        return false;
    };
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const handleNext = () => {
        if (
            stepperInputs[activeStep]
                .map((key) => formFelids[key].length === 0)
                .includes(true) && source !== 'marketplace'
        ) {
            setSnackBarMessage({
                message: "Please Fill all the details",
                severity: "error",
            });
            setSnackBarOpen(true);
            return;
        }
        let newSkipped = skipped;

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const HandelFinish = () => {
        if (
            stepperInputs[activeStep]
                .map((key) => formFelids[key].length === 0)
                .includes(true) && source !== 'marketplace'
        ) {
            // console.log("Please fill all the fields");
            setSnackBarMessage({
                message: "Please fill all the fields",
                severity: "error",
            });
            setSnackBarOpen(true);
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        const data = {
            awsAccessKey: formFelids.awsAccessKey,
            awsSecretKey: formFelids.awsSecretKey,
            region: formFelids.region,
            instanceType: formFelids.instanceType,
            orgName: formFelids.orgName,
            primaryStorage: formFelids.primaryStorage,
            secondaryStorage: formFelids.secondaryStorage,
            type: location.state.type,
            requestId: location.state.requestId,
            companyName: formFelids.companyName,
            dnsHostName: formFelids.dnsHostName,
            bundledCert: formFelids.bundledCert,
            certKey: formFelids.certKey

        };
        axios
            .post("/api/v1/eks", { data })
            .then((res) => {
                // console.log(res);
                setSnackBarMessage({
                    message: "Creating Cluster. It may take up to 30 mins. Kindly check the status after 30 mins",
                    severity: "success",
                });
                setSnackBarOpen(true);
                navigate("/launcher/eks", {
                    state: {
                        value: "0",
                        source: source
                    },
                });
            })
            .catch((err) => {
                // throw new Error(err);
                setSnackBarMessage({
                    message: "Cluster creation failed",
                    severity: "error",
                });
                setSnackBarOpen(true);

            });
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const handleSnackBarClose = (event, reason) => {
        setSnackBarOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Snackbar
                open={SnackBarOpen}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleSnackBarClose} severity={SnackBarMessage.severity}>
                    {SnackBarMessage.message}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: "100%",
                    bgcolor: "#fff",
                    minHeight: "85vh",
                }}
            >
                <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    style={{
                        backgroundColor: "#fff",
                        padding: "40px",
                    }}
                >


                    {currentSteps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            color: "#000",
                                        }}
                                    >
                                        {label}
                                    </Typography>
                                </StepLabel>
                                <StepContent>
                                    <React.Fragment>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                p: 8,
                                            }}
                                        >
                                            <WizardForm
                                                activeStep={activeStep}
                                                setFormFelids={setFormFelids}
                                                formFelids={formFelids}
                                                source={source}
                                            />
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                            <Button
                                                color="inherit"
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                            <Box sx={{ flex: "1 1 auto" }} />
                                            {isStepOptional(activeStep) && (
                                                <Button
                                                    color="inherit"
                                                    onClick={handleSkip}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Skip
                                                </Button>
                                            )}

                                            {activeStep === currentSteps.length - 1 ? (
                                                <Button variant="contained" onClick={HandelFinish}>
                                                    Finish
                                                </Button>
                                            ) : (
                                                <Button variant="contained" onClick={handleNext}>
                                                    Next
                                                </Button>
                                            )}
                                        </Box>
                                    </React.Fragment>
                                </StepContent>
                            </Step>
                        );
                    })}
                    {activeStep === currentSteps.length && (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    )}
                </Stepper>
            </Box>
        </ThemeProvider>
    );
}
