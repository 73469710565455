import React, {useEffect, useState} from "react";
import Table from "../../../../../Components/Element/Table/Table";

const tableHeaders = [
    {name: "CheckAll", type: "checkbox", editable: true, key: "id", wid: 10},
    {name: "BMC IP", type: "label", key: "bmcip"},
    {name: "OS IP", type: "label", key: "osip"},
    {name: "Hostname", type: "label", key: "hostname"},
];
const WorkerServers = (props) => {
    return (
        <div className="MasterServer-step-main">
            <Table
                columns={tableHeaders}
                loading={false}
                column={true}
                rows={props.tableData}
                customTableMessage={"No Compatible Server Available"}
                setCheckBoxData={props.setCheckBoxSelected}
                checkboxData={props.checkBoxSelected}
            />
        </div>
    )
}

export default WorkerServers
