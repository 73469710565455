import React from "react";
import Input from "../../../../../Components/Element/Input/Input";
import "./VirtualIPDetails.css";

const VirtualIPDetails = (props) => {
    return (
        <div className="clusterDetails-step-main">
            <h5 style={{ color: "red" }}>* Please dont use this IP anywhere else</h5>
            <div className="clusterdetails-input">
                <Input
                    styled={{
                        padding: "10px 5px",
                        borderRadius: "5px",
                        margin: "10px 0 0 0",
                    }}
                    name={"Kubernetes Elastic IP"}
                    initialValue={props?.kubernetesElasticIP}
                    placeholder={"xxx.xxx.xxx.xx"}
                    callback={props.setKubernetesElasticIP}
                />
            </div>
        </div>
    );
};

export default VirtualIPDetails;
