export function handleInputChange(setUpdate, rows, e, idx, key, type) {
    const initialData = [...rows];
    initialData[idx][key] = e.target.value;
    setUpdate(initialData);
}

// let maxCheckSelect = 1000000000;

export function handleCheckBoxChange(
    setCheckboxData,
    checkboxData,
    data,
    maxCheck
) {
    const selectedIndex = checkboxData.indexOf(data);
    let newSelected = [];
    const maxCheckSelect = maxCheck?maxCheck:1000000000;
    // if (maxCheck) {
    //     maxCheckSelect = maxCheck;
    // }
    if (selectedIndex === -1) {
        if (maxCheckSelect !== checkboxData.length) {
            newSelected = newSelected.concat(checkboxData, data);
        } else {
            newSelected = [...checkboxData];
        }
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(checkboxData.slice(1));
    } else if (selectedIndex === checkboxData.length - 1) {
        newSelected = newSelected.concat(checkboxData.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            checkboxData.slice(0, selectedIndex),
            checkboxData.slice(selectedIndex + 1)
        );
    }
    setCheckboxData(newSelected);
}

export const handleSelectAllClick = (event, rows, setCheckboxData) => {
    if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.id);
        setCheckboxData(newSelecteds);
        return;
    }
    setCheckboxData([]);
};