import React from "react";
import { useLocation } from "react-router-dom";

import "./EditEksCluster.css"
import EksEditClusterForm from "./EksEditClusterForm/EksEditClusterForm";


const EditEksCluster = () => {
  const location = useLocation();
  
  return (
    <div> <EksEditClusterForm propsData={location.state.data}/></div>
  );
};

export default EditEksCluster;
