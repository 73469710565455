import React from 'react'
import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

function Marketplace_step_2({ formFelids, handleFormChange, source }) {
    return (
        <Grid container spacing={4}>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    Primary Storage
                </InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="primaryStorage"
                    type="number"
                    value={formFelids.primaryStorage}
                    onChange={handleFormChange}
                    disabled={source === 'marketplace'}
                    error={formFelids.primaryStorage < 100}
                    helperText={formFelids.primaryStorage < 100 && "Minimum Storage should be 100GB"}
                />
            </Grid>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    Secondary Storage
                </InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    type="number"
                    label=""
                    variant="outlined"
                    name="secondaryStorage"
                    value={formFelids.secondaryStorage}
                    onChange={handleFormChange}
                    disabled={source === 'marketplace'}
                    error={formFelids.secondaryStorage < 500}
                    helperText={formFelids.secondaryStorage < 500 && "Minimum Storage should be 500GB"}
                />
            </Grid>
        </Grid>
    )
}

export default Marketplace_step_2