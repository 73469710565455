import React, { useEffect, useState } from "react";
import axios from "axios";

import "./AddWorker.css";
import Stepper from "../../../Components/Element/Stepper/Stepper";
import SelectCluster from "./Steps/SelectCluster/SelectCluster";
import WorkerServers from "./Steps/WorkerServers/WorkerServers";

const AddWorker = (props) => {
    const [clusterTableData, setClusterTableData] = useState([]);
    const [workerTableData, setWorkerTableData] = useState([]);
    const [workerSelected, setWorkerSelected] = useState([]);
    const [clusterSelected, setClusterSelected] = useState([]);

    const addWorkerServers = [
        {
            label: "Select Cluster to Add Worker Nodes",
            content: (
                <SelectCluster
                    setCheckBoxSelected={setClusterSelected}
                    checkBoxSelected={clusterSelected}
                    tableData={clusterTableData}
                />
            ),
        },
        {
            label: "Select Worker Servers",
            content: (
                <WorkerServers
                    setCheckBoxSelected={setWorkerSelected}
                    checkBoxSelected={workerSelected}
                    tableData={workerTableData}
                />
            ),
        }
    ];
    const getActiveServersForWorkerAdd = () => {
        axios
            .get("/api/v1/server", {
                params: {
                    isActive: true,
                    osInstallationStatus: "completed",
                    isMaster: false,
                    isWorker: false,
                },
            })
            .then((res) => {
                const data = res.data.data.map((server) => {
                    return {
                        id: server._id,
                        bmcip: server.bmcip,
                        osip: server.osip,
                        hostname: server.hostname,
                    };
                });
                setWorkerTableData(data);
            });
    };
    const getActiveClusterForWorkerAdd = () => {
        if (props.modalType == "EKS") {
            axios
                .get("/api/v1/eks", {
                    params: {
                        status: "Active",
                    },
                })
                .then((eksRes) => {
                    const eksData = eksRes.data.data.map((cluster) => {
                        return {
                            id: cluster._id,
                            k8sId: (cluster._id).slice(-8),
                            k8sClusterName: cluster.orgName,
                        };
                    });
                    setClusterTableData(eksData);
                })
                .catch((error) => {
                    console.error("Error fetching EKS clusters:", error);
                });
        }

        if (props.modalType == "AKS") {
            axios
                .get("/api/v1/aks", {
                    params: {
                        status: "Active",
                    },
                })
                .then((aksRes) => {
                    const aksData = aksRes.data.data.map((cluster) => {
                        return {
                            id: cluster._id,
                            k8sId: (cluster._id).slice(-8),
                            k8sClusterName: cluster.orgName,
                        };
                    });
                    setClusterTableData((prevData) => [...prevData, ...aksData]);
                })
                .catch((error) => {
                    console.error("Error fetching AKS clusters:", error);
                });
        }

    };

    function handleWorkerAddition() {
        console.log(clusterSelected, "clusterselected");
        if (props.modalType == "EKS") {
            axios.post("/api/v1/eks/addWorker", {
                clusterID: clusterSelected,
                workerIPs: workerSelected,
            }).then((eksRes) => {
                props.setSnackbarOpen(true);
                props.setSnackbarData({ message: "Worker Added Successfully", severity: "success" });
            }).catch((err) => {
                console.log(err);
                props.setSnackbarOpen(true);
                props.setSnackbarData({ message: "Error in adding worker", severity: "error" });
            })
        } if (props.modalType == "AKS") {
            axios.post("/api/v1/aks/addWorker", {
                clusterID: clusterSelected,
                workerIPs: workerSelected,
            }).then((eksRes) => {
                props.setSnackbarOpen(true);
                props.setSnackbarData({ message: "Worker Added Successfully", severity: "success" });
            }).catch((err) => {
                console.log(err);
                props.setSnackbarOpen(true);
                props.setSnackbarData({ message: "Error in adding worker", severity: "error" });
            })
        }
    }


    useEffect(() => {
        getActiveClusterForWorkerAdd();
        getActiveServersForWorkerAdd()
    }, []);

    return (
        <Stepper
            finishCallback={handleWorkerAddition}
            steps={addWorkerServers}
            currentMaxSteps={
                (clusterSelected.length === 1) +
                (workerSelected.length !== 0) +
                1
            }
            setOpen={props.setOpen}
        />
    );
};

export default AddWorker;
