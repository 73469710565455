import React, { useState } from "react";
import left_image from "../../Assets/Images/mainImage.svg";
import logo from "../../Assets/Images/logo.svg";
import Input from "../../Components/Element/Input/Input";
import { Lock } from "@mui/icons-material";
import StyledButton from "../../Components/Element/Button/Button";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SnackbarComponent from "../../Components/Element/Snackbar/Snackbar";
import { handleReset } from "./ResetFunction";


const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    "& span": {
        [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
        },
    },
}));

const ResetPassword = () => {

    const { resetToken } = useParams();
    const navigation = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarData, setSnackbarData] = useState({ message: "", severity: "success" })
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);

    };

    return (
        <div className="login-window">
            <SnackbarComponent open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarData.message}
                severity={snackbarData.severity} />
            <div className="login-box login-left">
                <div className="login-content">
                    <img src={logo} alt="Zeblok Logo" className="login-logo" />
                    <div className="login-tagline">
                        Zeblok Enterprise Launcher
                    </div>
                    <img
                        src={left_image}
                        alt="zeblok image"
                        className="login-left-image"
                    />
                </div>
            </div>
            <div className="login-box login-right">
                <div className="login-content login-form">
                    <div className="login-welcome-title">Reset Password</div>
                    <div className="login-requirement-line">
                        Please enter your details below to reset your password
                    </div>
                    <Input
                        type={showPassword ? "text" : "password"}
                        name={"New Password"}
                        id={"login-email"}
                        icon={<Lock />}
                        placeholder={"Enter your new password"}
                        callback={setPassword}
                        styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }}
                        toggleView={true}
                        togglePasswordVisibility={handlePasswordToggle}
                    />
                    <Input
                        type={showPassword ? "text" : "password"}
                        name={"Confirm Password"}
                        id={"ConfirmPassword"}
                        icon={<Lock />}
                        placeholder={"Confirm your new password"}
                        callback={setConfirmPassword}
                        styled={{ padding: "10px", borderRadius: "5px", margin: "10px 0" }}
                        toggleView={true}
                        togglePasswordVisibility={handlePasswordToggle}
                    />
                    <div className="login-remember-section">
                        <FormGroup>
                            <StyledFormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Remember Me?"
                            />
                        </FormGroup>
                    </div>
                    <div className="login-btn" onClick={() => handleReset(password, confirmPassword, resetToken, navigation, setSnackbarOpen, setSnackbarData)}>
                        <StyledButton
                            styleDetails={{
                                padding: "9px 15px",
                                borderRadius: "10px",
                                bgcolor: "#881630",
                                bghover: "#ffc000",
                            }}
                            name={"Reset"}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
