import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    requests: [],
    loading: false,
};
export const getRequests = createAsyncThunk("getRequests", async (params, { dispatch }) => {
    try {
        const response = await axios.get("/api/v1/request");
        const data = response.data.data.map(item => {
            return {
                catalogue: item.catalogueId.name,
                proposal_by: item.proposalId.endCustomerDetails.user.name,
                proposal_organization: item.proposalId.endCustomerDetails.organization.name,
                ...item,
            }
        });
        return data;
    } catch (error) {
        return [];
    }
});
export const refreshRequests = createAsyncThunk("refreshRequests", async (params, { dispatch }) => {
    try {
        const response = await axios.get("/api/v1/request/refresh");
        const data = response.data.data.map(item => {
            return {
                catalogue: item.catalogueId.name,
                proposal_by: item.proposalId.endCustomerDetails.user.name,
                proposal_organization: item.proposalId.endCustomerDetails.organization.name,
                ...item,
            }
        })
        return data;
    } catch (error) {
        return [];
    }
});

export const requestSlice = createSlice({
    name: "requestSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRequests.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getRequests.fulfilled, (state, action) => {
            state.loading = false;
            state.requests = action.payload.map((item) => {
                if (!item.isDeleted) {
                    return {
                        catalogue: item.catalogueId.name,
                        proposal_by: item.proposalId.endCustomerDetails.user.name,
                        proposal_organization:
                            item.proposalId.endCustomerDetails.organization.name,
                        ...item,
                    };
                }
            });
        });
        builder.addCase(getRequests.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(refreshRequests.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(refreshRequests.fulfilled, (state, action) => {
            state.loading = false;
            state.requests = action.payload.map((item) => {
                if (!item.isDeleted) {
                    return {
                        catalogue: item.catalogueId.name,
                        proposal_by: item.proposalId.endCustomerDetails.user.name,
                        proposal_organization:
                            item.proposalId.endCustomerDetails.organization.name,
                        requestType: item.requestType,
                        ...item,
                    };
                }
            });
        });
        builder.addCase(refreshRequests.rejected, (state) => {
            state.loading = false;
        });
    },
});
// export const { deleteEksCluster } = EksSlice.actions;

// Action creators are generated for each case reducer function

export default requestSlice.reducer;
