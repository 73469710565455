import React, { useState } from "react";
import "./input.css";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Visibility, VisibilityOff } from "@mui/icons-material"; 

const Searchbar = (props) => {
    const [inputValue, setInputValue] = useState("");
    const [showPassword, setShowPassword] = useState(false); 

    const BoxStyle = {
        borderRadius: `${props.styled ? props.styled.borderRadius : "0"}`,
        margin: `${props.styled ? props.styled.margin : "0"}`,
        border: `${props.styled ? props.styled.border : "1px solid"}`,
    };

    const LabelStyle = {
        margin: `${props.labelstyled ? props.labelstyled.margin : "0"}`,
    };

    const InputStyle = {
        width: "100%",
        padding: `${props.styled ? props.styled.padding : "0"}`,
        background: "none",
    };

    const descriptionStyle = {
        fontSize: "12px",
        color: "red", // Set the color to red
        margin: "5px 0",
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            {props.name && <label style={LabelStyle} htmlFor={props.id}>{props.name}</label>}
            {inputValue && <p style={descriptionStyle}>{props.description}</p>}
            <div style={BoxStyle} className="inputBox-main">
                {props.icon && (
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            justifyContent: "center",
                        }}
                    >
                        {props.icon}
                    </ListItemIcon>
                )}

                <input
                    style={InputStyle}
                    id={props.id}
                    type={props.type === "password" && showPassword ? "text" : props.type} 
                    placeholder={props.placeholder}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        props.callback(e.target.value);
                    }}
                />
                {props.type === "password" && props.toggleView && ( 
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            justifyContent: "center",
                        }}
                        onClick={togglePasswordVisibility}
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />} 
                    </ListItemIcon>
                )}
            </div>
        </>
    );
};

export default Searchbar;
