import React, { useEffect, useState } from "react";
import axios from "axios";

import "./ChooseCluster.css";
import Stepper from "../../../Components/Element/Stepper/Stepper";
import SelectCluster from "./Steps/SelectCluster/SelectCluster";
import DomainName from "./Steps/DomainName/DomainName";
import MasterServers from "./Steps/MasterServers/MasterServer";
import WorkerServers from "../../AddEdge/AddWorker/Steps/WorkerServers/WorkerServers";
import ConfigureStorage from "./Steps/ConfigureStorage/ConfigureStorage";
import VirtualIPDetails from "./Steps/VirtualIPDetails/VirtualIPDetails";
import ClusterDetails from "./Steps/ClusterDetails/ClusterDetails";

const ChooseCluster = (props) => {
    const [kubernetesclusterId, setKubernetesClusterId] = useState("");
    const [kubernetesclusterName, setKubernetesClusterName] = useState("");
    const [kubernetesElasticIP, setKubernetesElasticIP] = useState("");
    const [diskPathStorage, setDiskPathStorage] = useState("");
    const [tableData, setTableData] = useState([]);
    const [masterSelected, setMasterSelected] = useState([]);
    const [workerSelected, setWorkerSelected] = useState([]);
    const [clusterSelected, setClusterSelected] = useState([])
    const [domainName, setDomainName] = useState("");
    const [certFile, setCertFile] = useState("");
    const [keyFile, setKeyFile] = useState("");
    const aiMicroCloudSteps = [
        {
            label: "Enter Clusters Detail",
            content: (
                <ClusterDetails
                    setKubernetesClusterId={setKubernetesClusterId}
                    setKubernetesClusterName={setKubernetesClusterName}
                />
            ),
        },
        {
            label: "Select 3 Master Servers",
            content: (
                <MasterServers
                    setCheckBoxSelected={setMasterSelected}
                    checkBoxSelected={masterSelected}
                    tableData={tableData}
                />
            ),
        },
        {
            label: "Select Worker Servers",
            content: (
                <WorkerServers
                    setCheckBoxSelected={setWorkerSelected}
                    checkBoxSelected={workerSelected}
                    tableData={tableData.filter(
                        (val) => masterSelected.indexOf(val.id) === -1
                    )}
                />
            ),
        },
        {
            label: "Enter disk path to be included in the storage",
            content: (
                <ConfigureStorage
                    setDiskPathStorage={setDiskPathStorage}
                />
            ),
        },
        {
            label: "Enter Virtual IP Details",
            content: (
                <VirtualIPDetails
                    setKubernetesElasticIP={setKubernetesElasticIP}
                />
            ),
        },
        {
            label: "Domain Details",
            content: (
                <DomainName
                    setDomainName={setDomainName}
                    setCertFile={setCertFile}
                    setKeyFile={setKeyFile}
                />
            )
        },
    ];
    const getActiveClusterForMicroCloud = () => {
        axios
            .get("/api/v1/eks", {
                params: {
                    status: "Active",
                    isAiMicroCloudInstalled: false,
                },
            })
            .then((eksRes) => {
                const eksData = eksRes.data.data.map((cluster) => {
                    return {
                        id: cluster._id,
                        k8sId: cluster._id.slice(-8),
                        k8sClusterName: cluster.ResourceGroupName,
                    };
                });
                axios
                    .get("/api/v1/aks", {
                        params: {
                            status: "Active",
                            isAiMicroCloudInstalled: false,
                        },
                    })
                    .then((aksRes) => {
                        const aksData = aksRes.data.data.map((cluster) => {
                            return {
                                id: cluster._id,
                                k8sId: cluster._id.slice(-8),
                                k8sClusterName: cluster.ResourceGroupName,
                            };
                        });
                        setTableData([...eksData, ...aksData]);
                    })
                    .catch((aksErr) => {
                        console.error("Error fetching AKS clusters:", aksErr);
                    });
            })
            .catch((eksErr) => {
                console.error("Error fetching EKS clusters:", eksErr);
            });
    };

    function handleMicroCloudInstall() {
        axios.post("/api/v1/eks/installMicrocloud", {
            clusterIDs: clusterSelected.map(cluster => cluster.id),
            domainName: domainName,
            certFile: certFile,
            keyFile: keyFile
        }).then((eksRes) => {
            console.log("EKS cluster install response:", eksRes);
            axios.post("/api/v1/aks/installMicrocloud", {
                clusterIDs: clusterSelected.map(cluster => cluster.id),
                domainName: domainName,
                certFile: certFile,
                keyFile: keyFile
            }).then((aksRes) => {
                console.log("AKS cluster install response:", aksRes);
                props.setSnackbarOpen(true);
                props.setSnackbarData({ message: "Installed Ai-Microcloud Successfully", severity: "success" });
            }).catch((aksErr) => {
                console.error("Error installing microcloud for AKS clusters:", aksErr);
                props.setSnackbarOpen(true);
                props.setSnackbarData({ message: "Error in Installing Ai-Microcloud for AKS clusters", severity: "error" });
            });
        }).catch((eksErr) => {
            console.error("Error installing microcloud for EKS clusters:", eksErr);
            props.setSnackbarOpen(true);
            props.setSnackbarData({ message: "Error in Installing Ai-Microcloud for EKS clusters", severity: "error" });
        });
        props.setOpen(false);
        console.log("Done");
    }
    function handleAddKubernteCLuster() {
        axios.post("/api/v1/baremetal", {
            k8sClusterName: kubernetesclusterName,
            k8sId: kubernetesclusterId,
            diskPathStorage: diskPathStorage,
            elasticIP: kubernetesElasticIP,
            workerServersArray: workerSelected,
            masterServerArray: masterSelected,
            domainName: domainName,
            certFile: certFile,
            keyFile: keyFile
        }).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
            alert("Error in Creating Cluster");
            return;
        });
        props.setOpen(false)
        console.log("Done");
    }
    const getActiveServersForKuber = () => {
        axios
            .get("/api/v1/server", {
                params: {
                    isActive: true,
                    // osInstallationStatus: "completed",
                    isMaster: false,
                    isWorker: false,
                },
            })
            .then((res) => {
                const data = res.data.data.map((server) => {
                    return {
                        id: server._id,
                        managementip: server.managementip,
                        dataip: server.dataip,
                        hostname: server.hostname,
                    };
                });
                setTableData(data);
            });
    };

    useEffect(() => {
        getActiveServersForKuber()
    }, []);

    return (
        <Stepper
            finishCallback={handleAddKubernteCLuster}
            steps={aiMicroCloudSteps}
            height="auto"
            width="50em"
            currentMaxSteps={
                6
            }
            setOpen={props.setOpen}
        />
    );
};

export default ChooseCluster;
