import React from 'react'
import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

function Marketplace_step_1({ formFelids, handleFormChange, awsRegion }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
            }}
        >
            <Grid container spacing={4}>
                <Grid item>
                    <InputLabel id="demo-simple-select-label">
                        AWS Access Key{" "}
                    </InputLabel>
                    <TextField
                        required
                        id="outlined-basic"
                        variant="outlined"
                        name="awsAccessKey"
                        type="password"
                        value={formFelids.awsAccessKey}
                        onChange={handleFormChange}
                    />
                </Grid>
                <Grid item>
                    <InputLabel id="demo-simple-select-label">
                        AWS Secret Key{" "}
                    </InputLabel>
                    <TextField
                        required
                        id="outlined-basic"
                        type="password"
                        label=""
                        variant="outlined"
                        name="awsSecretKey"
                        value={formFelids.awsSecretKey}
                        onChange={handleFormChange}
                    />
                </Grid>
                <Grid item>
                    <InputLabel id="demo-simple-select-label">Region </InputLabel>
                    <Select
                        sx={{ minWidth: 180 }}
                        id="demo-simple-select"
                        value={formFelids.region}
                        name="region"
                        onChange={handleFormChange}
                        required
                    >
                        {awsRegion.map((item) => {
                            return <MenuItem value={item.id}>{item.value}</MenuItem>;
                        })}
                    </Select>
                </Grid>
            </Grid>
        </div>
    )
}

export default Marketplace_step_1