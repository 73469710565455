import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card, Checkbox, FormControlLabel, FormGroup, Input, Switch } from "@mui/material";
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function PrivateEnvoirment({ CloudProvider, setCloudProvider, location, data }) {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Box
      padding={2}
      style={{
        display: "flex",
        gap: "20px",
      }}
    >
      <Box>
        <Typography id="modal-modal-description" sx={{}}>
          Select Cloud Provider
        </Typography>
        <Typography variant="caption">
          Select The Cloud Provider where you want to create
          <br /> the cluster
        </Typography>
        <Box>
          <FormGroup>
            <FormControlLabel label="OS installed?" control={<Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />} />
          </FormGroup>
        </Box>
      </Box>

      {<Card
        onClick={() => {
          setCloudProvider("Baremetal");

          location("/launcher/baremetal/create", {
            state: {
              OsInstalled: checked
            }
          })
        }
        }
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor:
            CloudProvider === "Baremetal" ? "#881630" : "white",
          color: CloudProvider === "Baremetal" ? "white" : "black",
          cursor: "pointer",
        }}
      >
        <Typography padding={4}>Baremetal</Typography>
      </Card>}
    </Box>
  )
}

export default PrivateEnvoirment