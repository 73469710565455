import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logout } from "../../slice/authSlice";

const Logout = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        axios.get("/api/v1/auth/logout").then(() => {
            dispatch(logout());
        })
        navigation("/");
    });

    return <div>Logout</div>;
};

export default Logout;
