import React from 'react'
import Servers from '../AddEdge/AddEdge'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import BlockBox from '../../Components/Element/Box/Box'
import AiMicrocloud from '../AiMicrocloud/AiMicrocloud';
import { useLocation } from 'react-router-dom';
import AddWorkerToCluster from '../AddEdge/AddWorkerToCluster/AddWorkerToCluster';
import AddWorker from '../AddEdge/AddWorker/AddWorker';

function Baremetal() {
    const [expanded, setExpanded] = React.useState(1);
    const location = useLocation();
    const { state } = location
    const { OsInstalled } = state
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div className="server-box">
            <BlockBox>
                <div className="server-block-header">
                    <div className="server-box-title">Configure Baremetal</div>
                </div>
                <div className="accordion-div">
                    <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>1. Add Servers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <Servers OsInstalled={OsInstalled} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 2} onChange={handleChange(2)}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>2. Install AI - Microcloud</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <AiMicrocloud />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 3} onChange={handleChange(3)}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>3. Add Edge server</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AddWorkerToCluster />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 4} onChange={handleChange(4)}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>4. Add Worker server</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AddWorker />
                        </AccordionDetails>
                    </Accordion>
                </div>
            </BlockBox>
        </div>
    )
}

export default Baremetal