import React from 'react'
import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';


function Marketplace_step_0({ formFelids, handleFormChange, source, InstanceType }) {
    return (
        <Grid container spacing={4}>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    Organization Name
                </InputLabel>
                <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="orgName"
                    value={formFelids.orgName}
                    onChange={handleFormChange}
                />
            </Grid>
            <Grid item>
                <InputLabel id="demo-simple-select-label">
                    Select Instance Type
                </InputLabel>
                <Select
                    sx={{ minWidth: 180 }}
                    id="demo-simple-select"
                    value={formFelids.instanceType}
                    name="instanceType"
                    onChange={handleFormChange}
                    required
                    disabled={source === 'marketplace'}
                >
                    {InstanceType.map((item) => {
                        return <MenuItem value={item.id}>{item.value}</MenuItem>;
                    })}
                </Select>
            </Grid>
        </Grid>
    )
}

export default Marketplace_step_0