import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'

function BasicInfo({ formFelids, handleFormChange, VMSizes }) {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item>
                    <InputLabel>Location</InputLabel>
                    <Select
                        sx={{ minWidth: 180 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formFelids.Location}
                        name="Location"
                        onChange={handleFormChange}
                        required
                    >
                        <MenuItem value={"East US"}>East US</MenuItem>
                        <MenuItem value={"West US"}>West US</MenuItem>
                        <MenuItem value={"Canada Central"}>Canada Central</MenuItem>
                        <MenuItem value={"West India"}>West India</MenuItem>
                        <MenuItem value={"southindia"}>South India</MenuItem>
                    </Select>
                </Grid>
                <Grid item>
                    <InputLabel>Resource Group Name</InputLabel>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="ResourceGroupName"
                        value={formFelids.ResourceGroupName}
                        onChange={handleFormChange}
                        required
                    />
                </Grid>
                <Grid item>
                <InputLabel>Resource Group Already Present?</InputLabel>
                    <Select
                        sx={{ minWidth: 180 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formFelids.ResourceGroupPresent}
                        name="ResourceGroupPresent"
                        onChange={handleFormChange}
                        required
                    >
                        <MenuItem value={false}>Yes</MenuItem>
                        <MenuItem value={true}>No</MenuItem>
                        </Select>
                </Grid>
            </Grid>
        </>
    )
}

export default BasicInfo