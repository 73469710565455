import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import Login from "./Page/Login/Login";
import Logout from "./Page/Logout/Logout";
import ProtectedRoute from "./Protected";
import MainLayout from "./Components/Layout/Layout";
import EKS from "./Page/EKS/EKS";
import CreateEksCluster from "./Page/EKS/CreateEksCluster/CreateEksCluster";
import CreateAksCluster from "./Page/AKS/CreateAksCluster/CreateAksCluster";
import EksClusterInfo from "./Page/EKS/EksCluster/Cluster";
import AiMicrocloud from "./Page/AiMicrocloud/AiMicrocloud";
import AddEdge from "./Page/AddEdge/AddEdge";
import Request from "./Page/Request/Request";
import Security from "./Page/Security/Security";
import WelcomeComponent from "./Page/Welcome/Welcome";
import ResetPassword from "./Page/ResetPassword/ResetPassword";
import EditEksCluster from "./Page/EKS/EditEksCluster/EditEksCluster";
import EditAksCluster from "./Page/AKS/EditAksCluster/EditAksCluster";
import AKS from "./Page/AKS/AKS";
import AksClusterInfo from "./Page/AKS/AksCluster/Cluster";
import CreateBaremetal from "./Page/Baremetal/Baremetal";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/welcome-page/:name" element={<WelcomeComponent />} />
          <Route path="/resetPassword/:resetToken" element={<ResetPassword />} />
          <Route
            path="launcher"
            element={<ProtectedRoute component={MainLayout} />}
            exact
          >
            <Route
              path="logout"
              element={<ProtectedRoute component={Logout} />}
              exact
            />
            <Route
              path="eks"
              element={<ProtectedRoute component={EKS} />}
              exact
            />
            <Route
              path="aks"
              element={<ProtectedRoute component={AKS} />}
              exact
            />
            {/* <Route
              path="baremetal"
              element={<ProtectedRoute component={Baremetal} />}
              exact
            /> */}
            <Route
              path="baremetal/create"
              element={<ProtectedRoute component={CreateBaremetal} />}
              exact
            />
            {/* <Route
              path="cluster/create"
              element={<ProtectedRoute component={CreateCluster} />}
              exact
            />
            <Route
              path="cluster/edit/:id"
              element={<ProtectedRoute component={EditCluster} />}
              exact
            />
            <Route
              path="cluster/:id"
              element={<ProtectedRoute component={ClusterInfo} />}
              exact
            /> */}
            <Route
              path="eks/create"
              element={<ProtectedRoute component={CreateEksCluster} />}
              exact
            />
            <Route
              path="eks/edit/:id"
              element={<ProtectedRoute component={EditEksCluster} />}
              exact
            />
            <Route
              path="eks/:id"
              element={<ProtectedRoute component={EksClusterInfo} />}
              exact
            />
            <Route
              path="aks/create"
              element={<ProtectedRoute component={CreateAksCluster} />}
              exact
            />
            <Route
              path="aks/edit/:id"
              element={<ProtectedRoute component={EditAksCluster} />}
              exact
            />
            <Route
              path="aks/:id"
              element={<ProtectedRoute component={AksClusterInfo} />}
              exact
            />
            <Route
              path="aimicrocloud"
              element={<ProtectedRoute component={AiMicrocloud} />}
              exact
            />
            <Route
              path="addedge"
              element={<ProtectedRoute component={AddEdge} />}
              exact
            />
            <Route
              path="security"
              element={<ProtectedRoute component={Security} />}
              exact
            />
            <Route
              path="request"
              element={<ProtectedRoute component={Request} />}
              exact
            />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
