import React, { useEffect, useState } from "react";
import { CachedOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import Searchbar from "../../Components/Element/Searchbar/Searchbar";
import BlockBox from "../../Components/Element/Box/Box";
import StyledButton from "../../Components/Element/Button/Button";
import './Request.css'
import Table from "../../Components/Element/Table/Table";
import SpringModal from "../../Components/Element/Modal/Modal";
import ViewMore from "./ViewMore/ViewMore";
import { getRequests, refreshRequests } from "../../slice/requestSlice";

const tableHeaders = [
    { name: "Catalogue", type: "label", key: "catalogue" },
    { name: "Customer Admin", type: "label", key: "proposal_by" },
    { name: "Customer Organization", type: "label", key: "proposal_organization" },
    { name: "Status", type: "label", key: "status" },
    { name: "Action", type: "action", key: "action" },
];
const Request = () => {
    const dispatch = useDispatch();

    const clusterData = useSelector((state) => state.request.requests);
    const isLoading = useSelector((state) => state.request.loading);

    const [rowIndex, setRowIndex] = useState({});
    const [openRow, setOpenRow] = useState(false);

    const showDetails = (e) => {
        handleViewToggle()
        setRowIndex(e)
    }

    const handleViewToggle = () => {
        setOpenRow(!openRow)
    }

    useEffect(() => {
        dispatch(getRequests())
    }, [])

    return (
        <div>
            <SpringModal styleModal={{ width: "80vw", lgWidth: "80vw", mdWidth: "97vw" }}
                open={openRow} setOpen={setOpenRow}>
                <ViewMore tableData={clusterData} rowIndex={rowIndex}
                    toggleOpenState={handleViewToggle} />
            </SpringModal>
            <div className="server-main">
                <div className="server-box">
                    <BlockBox>
                        <div className="server-block-header">
                            <div className="server-box-title">All Requests</div>
                            <div className="server-block-header-right">
                                <div style={{ marginRight: "15px" }}>
                                    <Searchbar />
                                </div>
                                <StyledButton
                                    styleDetails={{
                                        padding: "9px 15px",
                                        borderRadius: "10px",
                                        bgcolor: "#881630",
                                        bghover: "#ffc000",
                                        margin: "0 5px"
                                    }}
                                    name={"Refresh"}
                                    endIcon={<CachedOutlined />}
                                    onClickFunc={() => { dispatch(refreshRequests()) }}
                                />
                            </div>
                        </div>

                        <div className="server-table-cover">
                            <Table columns={tableHeaders} showDetails={showDetails} loading={isLoading} column={true}
                                rows={clusterData} />
                        </div>
                    </BlockBox>
                </div>
            </div>
        </div>
    )
}

export default Request;
